<template>
  <el-select :disabled="status" @change="changeValue" v-model="type" placeholder="选择纳税人类型" size="small" style="width: 120px;" clearable>
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"  size="small" >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'select',
  props: {
    status: false,
    options: Array,
    type: ''
  },
  data() {
    return {
      options:[
        {
          value:'1',
          label:'小规模纳税人'
        },
        {
          value:'2',
          label:'一般纳税人'
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
    changeValue() {
      this.$emit('update:type', this.type)
      this.$emit("success",this.type)
    },   
  }
}
</script>

<style scoped>

</style>
<!-- 参数type  回调方法select -->