<template>
   <el-dialog :close-on-click-modal="false"
    v-model="dialogFormVisible"
    :title="name"
    width="90%"
    destroy-on-close
    append-to-body
    >
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 中华人民共和国企业所得税年度纳税申报表（A类） -->
    <div class="big_box4">
     <el-scrollbar :height="contentStyleObj">
      <div class="top_title3">
        <h4>中华人民共和国企业所得税年度纳税申报表（A类）</h4>
        <!-- <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>2020-2-25</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div> -->
      </div>
      <table class="content" cellspacing="0" width="100%">
        <tr class="top_bg">
         <td class="center" style="width:4%;">行次</td>
         <td class="center" style="width:8%;">类别</td>
         <td class="center" style="width:68%;">项目</td>
         <td class="center" style="width:20%;">金额</td>
        </tr>
      </table>
      <div class="fixed_box_b">
        <table class="content" cellspacing="0">
          <tr>
            <td class="center" style="width:4%;">1</td>
            <td class="center" rowspan= '18' style="width:8%;">利润总额计算</td>
            <td style="width:68%;">一、营业收入(填写A101010\101020\103000)</td>
            <qzf-str-td class="blue_box" style="width:20%;" :disabled="false" v-model:amount="info[0].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">2</td>
            <td class="left_k">减：营业成本(填写A102010\102020\103000)</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[1].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">3</td>
            <td class="left_k">减：税金及附加</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[2].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">4</td>
            <td class="left_k">减：销售费用(填写A104000)</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[3].amount" @change="changeList()"></qzf-str-td>
          </tr>

          <tr>
            <td class="center">5</td>
            <td class="left_k">减：管理费用(填写A104000)</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[4].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">6</td>
            <td class="left_k">减：研发费用(填写A104000)</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[5].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">7</td>
            <td class="left_k">减：财务费用(填写A104000)</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[6].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">8</td>
            <td class="left_k">加：其他收益</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[7].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">9</td>
            <td class="left_k">加：投资收益（损失以‘-’号填列）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[8].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">10</td>
            <td class="left_k">加：净敞口套期收益（损失以‘－’号填列）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[9].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">11</td>
            <td class="left_k">加：公允价值变动收益（损失以‘－’号填列）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[10].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">12</td>
            <td class="left_k">加：信用减值损失（损失以‘－’号填列）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[11].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">13</td>
            <td class="left_k">加：资产减值损失（损失以‘－’号填列）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[12].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">14</td>
            <td class="left_k">加：资产处置收益（损失以‘－’号填列）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[13].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">15</td>
            <td>二、营业利润（损失以‘－’号填列）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[14].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">16</td>
            <td class="left_k">加：营业外收入(填写A101010\101020\103000)</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[15].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">17</td>
            <td class="left_k">减：营业外支出(填写A102010\102020\103000)</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[16].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">18</td>
            <td>三、利润总额（15+16-17）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[17].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">19</td>
            <td class="center" rowspan= '10'>应纳税所得额计算</td>
            <td>减：境外所得（填写A108010）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[18].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">20</td>
            <td class="left_k">加：纳税调整增加额（填写A105000）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[19].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">21</td>
            <td class="left_k">减：纳税调整减少额（填写A105000）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[20].amount" @change="changeList()"></qzf-str-td>
          </tr>

          <tr>
            <td class="center">22</td>
            <td class="left_k">减：免税、减计收入及加计扣除（22.1+22.2+...） <span class="clickable_style" @click="msItem">填写优惠事项</span></td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[21].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">23</td>
            <td class="left_k">加：境外应税所得抵减境内亏损（填写A108000）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[22].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">24</td>
            <td>四、纳税调整后所得（18-19+20-21-22+23）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[23].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">25</td>
            <td class="left_k">减：所得减免（填写A107020）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[24].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">26</td>
            <td class="left_k">减：弥补以前年度亏损（填写A106000）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[25].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">27</td>
            <td class="left_k">减：抵扣应纳税所得额（填写A107030）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[26].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">28</td>
            <td>五、应纳税所得额（24-25-26-27）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[27].amount" @change="changeList()"></qzf-str-td>
          </tr>

          <tr>
            <td class="center">29</td>
            <td class="center" rowspan= '8'>应纳税额计算</td>
            <td>税率（25%）</td>
            <td class="gary_box">{{info[28].amount}}</td>
          </tr>
          <tr>
            <td class="center">30</td>
            <td>六、应纳所得税额（28×29）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[29].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">31</td>
            <td class="left_k">减：减免所得税额（31.1+31.2+...）<span class="clickable_style" @click="jsItem">填写优惠事项</span></td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[30].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">32</td>
            <td class="left_k">减：抵免所得税额（填写A107050）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[31].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">33</td>
            <td>七、应纳税额（30-31-32）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[32].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">34</td>
            <td class="left_k">加：境外所得应纳所得税额（填写A108000）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[33].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">35</td>
            <td class="left_k">减：境外所得抵免所得税额（填写A108000）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[34].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">36</td>
            <td>八、实际应纳所得税额（33+34-35）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[35].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">37</td>
            <td class="center" rowspan= '30'>实际应补（退）税额计算</td>
            <td class="left_k">减：本年累计预缴所得税额</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[36].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">37.1</td>
            <td class="left_f">各期预缴应补（退）所得税额合计</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[37].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">37.2</td>
            <td class="left_f">特定业务累计已预缴所得税额</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[38].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">37.3</td>
            <td class="left_f">本年预缴开票企业所得税额</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[39].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">37.4</td>
            <td class="left_f">本年预缴申报误收退税金额</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[40].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">38</td>
            <td>九、本年应补（退）所得税额（36-37）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[41].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">39</td>
            <td class="left_k">其中：总机构分摊本年应补（退）所得税额(填写A109000)</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[42].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">40</td>
            <td class="left_indent">财政集中分配本年应补（退）所得税额（填写A109000）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[43].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">41</td>
            <td class="left_indent">总机构主体生产经营部门分摊本年应补（退）所得税额(填写A109000)</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[44].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">FZ1</td>
            <td>中央级收入应补（退）所得税额【一般企业38×60%；总机构（39+40+41）*60%】</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[45].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">FZ2</td>
            <td>中央待分配收入应补（退）所得税额【跨省总机构（39+40）*20%】</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[46].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">FZ3</td>
            <td>地方级收入应补（退）所得税额【一般企业38×40%；跨省总机构（39+40）*20%+41*40%；总机构（39+40+41）*40%）】</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[47].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">42</td>
            <td>本年民族自治地区地方分享部分优惠方式</td>
            <td class="blue_box">
              <el-radio-group v-model="info[48].amount" size="small">
                <el-radio label="免征">免征</el-radio>
                <el-radio label="减征">减征</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
              <!-- <input type="text" v-model="info[38].amount"> -->
            </td>
          </tr>
          <tr>
            <td class="center">42.0</td>
            <td>优惠幅度（0为不减免，100%为免征）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[49].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">42.1</td>
            <td>本年应减免金额（一般企业36行×40%×42.0行“优惠幅度”；总机构A109000表第23行）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[50].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">42.2</td>
            <td>本年累计已减免金额（本年度4季度预缴申报表23.1行，总机构A109000表第24行）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[51].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">42.3</td>
            <td>因优惠产生的地方级收入应补（退）金额(一般企业42.1-42.2；总机构A109000第25行)</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[52].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">42.4</td>
            <td>总机构及分支机构地方级收入全年减免总额（42.1+A109010第13列合计）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[53].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">43</td>
            <td>减：稽查查补（退）所得税额</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[54].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">FZ4</td>
            <td class="left_k">其中：稽查查补中央级收入应补（退）所得税额</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[55].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">FZ5</td>
            <td class="left_indent">稽查查补中央待分配收入应补（退）所得税额</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[56].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">FZ6</td>
            <td class="left_indent">稽查查补地方级收入应补（退）所得税额</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[57].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">44</td>
            <td>减：特别纳税调整补（退）所得税额</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[58].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">FZ7</td>
            <td class="left_k">其中：特别纳税调整中央级收入应补（退）所得税额</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[59].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">FZ8</td>
            <td class="left_indent">特别纳税调整中央待分配收入应补（退）所得税额</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[60].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">FZ9</td>
            <td class="left_indent">特别纳税调整地方级收入应补（退）所得税额</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[61].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">FZ10</td>
            <td>中央级收入实际应补（退）所得税额（FZ1-FZ4-FZ7）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[62].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">FZ11</td>
            <td>中央待分配收入实际应补（退）所得税额（FZ2-FZ5-FZ8）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[63].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">FZ12</td>
            <td>地方级收入实际应补（退）所得税额（FZ3-42.3-FZ6-FZ9）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[64].amount" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">45</td>
            <td>十、本年实际应补（退）所得税额（一般企业38-42.3-43-44行；总机构A109000第26行）</td>
            <qzf-str-td class="blue_box" :disabled="false" v-model:amount="info[65].amount" @change="changeList()"></qzf-str-td>
          </tr>
        </table>
      </div>
      </el-scrollbar>
      <div class="save_box">
        <el-button type="primary" size="small" @click="saveDetail()" :disabled="!$buttonStatus('hsqj_bbbc')"><i class="iconfont icon-baocun"></i>保存</el-button>
      </div>
    </div>
    
    <!-- 中华人民共和国企业所得税年度纳税申报表（A类） end -->
  </div>
</el-dialog>
  <!-- 免税 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close title="免税、减计收入、加计扣除类型选项表" v-model="msVisible" width="1100px" append-to-body>
    <el-scrollbar :maxHeight="contentStyleObj">
      <table class="content" cellspacing="0" width="100%">
        <tr class="top_bg center">
          <td style="width:5%;">序号</td>
          <td style="width:5%">选择</td>
          <td style="width:10%">代码</td>
          <td style="width:57%">优惠事项</td>
          <td style="width:15%">金额</td>
        </tr>
      </table>
      <table class="content" cellspacing="0">
        <tr v-for="(item, index) in detailMsInfo" :key="index">
          <td style="width:5%;" class="center">{{ index + 1 }}</td>
          <td style="width:5%;" class="center">
            <el-checkbox v-model="item.isCheck" :true-label="1" :false-label="0"></el-checkbox>
          </td>
          <td style="width:10%;" class="left">{{ item.code }}</td>
          <td style="width:57%;" class="left">
            <span v-if="item.code == 'MSSR99999'">
              其他免税收入类未列明优惠 （减免税代码：  <el-input size="small" v-model="item.other" class="w-160"></el-input>）
            </span>
            <span v-else-if="item.code == 'JJSR99999'">
              其他减计收入类未列明优惠 （减免税代码：  <el-input size="small" v-model="item.other" class="w-160"></el-input>）
            </span>
            <span v-else>
              {{ item.project }}
            </span>
          </td>
          <qzf-str-td style="width:15%;" :disabled="false" v-model:amount="item.amount"></qzf-str-td>
        </tr>
      </table>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" size="small" @click="saveMs()">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 免税end -->
  <!-- 减税 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close title="减免所得税额" v-model="jsVisible" width="1100px" append-to-body>
    <el-scrollbar :maxHeight="contentStyleObj">
      <table class="content" cellspacing="0" width="100%">
        <tr class="top_bg center">
          <td style="width:5%;">序号</td>
          <td style="width:5%">选择</td>
          <td style="width:10%">代码</td>
          <td style="width:57%">优惠事项</td>
          <td style="width:15%">金额</td>
        </tr>
      </table>
      <table class="content" cellspacing="0">
        <tr v-for="(item, index) in jmInfo" :key="index">
          <td style="width:5%;" class="center">{{ index + 1 }}</td>
          <td style="width:5%;" class="center">
            <el-checkbox v-model="item.isCheck" :true-label="1" :false-label="0"></el-checkbox>
          </td>
          <td style="width:10%;" class="left">{{ item.code }}</td>
          <td style="width:57%;" class="left">
            <span v-if="item.code == 'JMSE00601'">
              设在西部地区的鼓励类产业企业减按15%的税率征收企业所得税（主营业务收入占比<el-input size="small" v-model="item.other" class="w-160"></el-input>%）
            </span>
            <span v-else-if="item.code == 'JMSE01101'">
              符合条件的公司型创投企业按照企业年末个人股东持股比例减免企业所得税（个人股东持股比例<el-input size="small" v-model="item.other" class="w-160"></el-input>% ）
            </span>
            <span v-else-if="item.code == 'JMSE99999'">
              其他减免税额类未列明优惠 （减免税代码：  <el-input size="small" v-model="item.other" class="w-160"></el-input>）
            </span>
            <span v-else>
              {{ item.project }}
            </span>
          </td>
          <qzf-str-td style="width:15%;" :disabled="false" v-model:amount="item.amount"></qzf-str-td>
        </tr>
      </table>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" size="small" @click="saveJs()">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 减税end -->
</template>
<script>
import { yearInfo, yearEdit } from "@/api/year"
export default {
  name: "A100000",
  props: {
    tableYearName: {
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
    name:{
      default:'',
      type:String
    }
  },
  data() {
    return {
      comName: '',
      taxNo: '',
      info:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      fullscreenLoading:false,
      dialogFormVisible:false,
      contentStyleObj:{},
      detailMsInfo:[],
      msVisible:false,
      jmInfo:[],
      jsVisible:false,
    };
  },
  created() {
    // this.comName = this.$store.getters['user/comInfo'].comName
    // this.taxNo = this.$store.getters['user/comInfo'].taxNo
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(230)
  },
  methods: {
    getParams() {
      this.dialogFormVisible = true
      this.getDetail()
    },
    getDetail() {
      this.fullscreenLoading = true
      let param = {
        comId:this.comId,
        period:this.startAccountPeriod,
        tableName:this.tableYearName
      }
      yearInfo(param).then(response => {
       this.fullscreenLoading = false
        if(response.data.data && response.data.data.length == 66){
          this.info = response.data.data;
        }else{
          this.$qzfMessage('税局更新，请重新取数',1)
        }
      });
    },
    // 点击保存
    saveDetail(){
      this.fullscreenLoading = true
      let param = {
        comId:this.comId,
        year:this.startAccountPeriod,
        tableName:this.tableYearName,
        items:this.info
      }
      yearEdit(param).then(res => {
        this.fullscreenLoading = false
        if(res.data.msg == 'success'){
         this.$qzfMessage('保存成功')
         this.getDetail()
        }
      });
    },
    //填写免税事项
    msItem(){
      this.msVisible = true
      let param = {
        comId:this.comId,
        period:this.startAccountPeriod,
        tableName:'year_a100000_fb1'
      }
      yearInfo(param).then(res => {
        if(res.data.msg == 'success'){
          this.detailMsInfo = res.data.data || []
        }
      });
    },
    saveMs(){
      let param = {
        comId:this.comId,
        year:this.startAccountPeriod,
        tableName:'year_a100000_fb1',
        items:this.detailMsInfo
      }
      yearEdit(param).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage('保存成功')
          this.msVisible = false
          this.computedMssr()
        }
      })
    },
    computedMssr(){
      let sum = 0
      this.detailMsInfo.map(v=>{
        if(v.isCheck && v.amount){
          sum += Number(v.amount)
        }
      })
      this.info[21].amount = sum.toFixed(2);
    },
    //减税
    jsItem(){
      this.jsVisible = true
      let param = {
        comId:this.comId,
        period:this.startAccountPeriod,
        tableName:'year_a100000_fb2'
      }
      yearInfo(param).then(res => {
        if(res.data.msg == 'success'){
          this.jmInfo = res.data.data || []
        }
      });
    },
    saveJs(){
      let param = {
        comId:this.comId,
        year:this.startAccountPeriod,
        tableName:'year_a100000_fb2',
        items:this.jmInfo
      }
      yearEdit(param).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage('保存成功')
          this.jsVisible = false
          this.computedJssr()
        }
      })
    },
    computedJssr(){
      let sum = 0
      this.jmInfo.map(v=>{
        if(v.isCheck && v.amount){
          sum += Number(v.amount)
        }
      })
      this.info[30].amount = sum.toFixed(2);
    },
    //计算公式
    changeList(){
      //15=1-2-3-4-5-6-7+8+9+10+11+12+13+14
      this.info[14].amount = (this.info[0].amount*1 - this.info[1].amount*1 - this.info[2].amount*1 - this.info[3].amount*1 - this.info[4].amount*1 - this.info[5].amount*1 - this.info[6].amount*1 + this.info[7].amount*1 + this.info[8].amount*1 + this.info[9].amount*1 + this.info[10].amount*1 + this.info[11].amount*1 + this.info[12].amount*1 +this.info[13].amount*1).toFixed(2)
      //18=15+16-17
      this.info[17].amount = (this.info[14].amount*1 + this.info[15].amount*1 - this.info[16].amount*1).toFixed(2)
      //24=18-19+20-21-22+23
      this.info[23].amount = (this.info[17].amount*1 - this.info[18].amount*1 + this.info[19].amount*1 - this.info[20].amount*1 - this.info[21].amount*1 + this.info[22].amount*1).toFixed(2)
      //28=24-25-26-27
      this.info[27].amount = (this.info[23].amount*1 - this.info[24].amount*1 - this.info[25].amount*1 - this.info[26].amount*1).toFixed(2)
      //30=28*0.25
      this.info[29].amount = (this.info[27].amount*1*0.25).toFixed(2)
      //33=30-31-32
      this.info[32].amount = (this.info[29].amount*1 - this.info[30].amount*1 - this.info[31].amount*1).toFixed(2)
      //36=33+34-35
      this.info[35].amount = (this.info[32].amount*1 + this.info[33].amount*1 - this.info[34].amount*1).toFixed(2)
      //38=36-37
      this.info[41].amount = (this.info[35].amount*1 - this.info[36].amount*1).toFixed(2)
      //FZ1=38*0.6
      this.info[45].amount = (this.info[41].amount*1*0.6).toFixed(2)
      //FZ3=38*0.4
      this.info[47].amount = (this.info[41].amount*1*0.4).toFixed(2)
      //42.1=36*0.4*42.0
      this.info[50].amount = ((this.info[35].amount*1*0.4)*(this.info[49].amount*1/100)).toFixed(2)
      //42.3=42.1-42.2
      this.info[52].amount = (this.info[50].amount*1-this.info[51].amount*1).toFixed(2)
      //42.4=42.1
      this.info[53].amount = this.info[50].amount
      //FZ10=FZ1-FZ4-FZ7
      this.info[62].amount = (this.info[45].amount*1-this.info[55].amount*1-this.info[59].amount*1).toFixed(2)
      //FZ11=FZ2-FZ5-FZ8
      this.info[63].amount = (this.info[46].amount*1 - this.info[56].amount*1 - this.info[60].amount*1).toFixed(2)
      //FZ12=FZ3-42.3-FZ6-FZ9
      this.info[64].amount = (this.info[47].amount*1 - this.info[52].amount*1 - this.info[57].amount*1 - this.info[61].amount*1).toFixed(2)
      //45=38-42.3-43-44
      this.info[65].amount = (this.info[41].amount*1 - this.info[52].amount*1 - this.info[54].amount*1 - this.info[58].amount*1)
    }
  }
};
</script>

<style lang="scss" scoped>
.border_none .el-select{
  width: 100% !important;
}
.center{
  text-align: center;
}
.border_none .el-input__inner{
  width: 100% !important;
  background-color: none !important;
}

.el-radio+.el-radio {
    margin-left: 7px;
}
.el-radio__label{
  font-size: 12px !important;
}
.input_width input{
  width:60px !important;
  margin: 0 5px;
}
.width_big{
  width: 30%;
}
.width_small{
  width: 20%;
}
.width_medium{
  width: 18%;
}
.width_ss{
  width: 15%;
}
.width_xs{
  width:13%;
}
.gary_box{
  background: #f8f8f8;
  text-align: right;
  padding-right: 20px !important;
}
.blue_box{
  background: #EBF5FF ;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 12px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #666;
    font-size: 12px;
  }
  input{
    width: 100%;
    line-height: 28px;
    // padding: 0 6px;
    text-align: right;
    font-size: 12px;
  }
}
.left_k{
  text-indent: 2em;
}
.left_f{
  text-indent: 4em;
}
.left_indent{
  text-indent: 5em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  background: #fff;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>



