<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="coopCustomerVisible"
    width="50%"
    title="新增合作客户"
  >
    <div class="content">
      <el-form
        ref="customerForm"
        :model="customerRuleForm"
        :rules="rules"
        label-width="120px"
        status-icon
        size="small"
      >
        <el-form-item label="公司名称" prop="customerName">
          <el-input v-model="customerRuleForm.customerName" />
        </el-form-item>
        <el-form-item label="业务负责人" prop="userId">
          <business
            code="coopCustom"
            v-model:userId="customerRuleForm.userId"
          ></business>
        </el-form-item>
        <el-form-item label="联系人" prop="linkman">
          <el-input v-model="customerRuleForm.linkman" />
        </el-form-item>
        <el-form-item label="电话" prop="linkmanTel">
          <el-input v-model="customerRuleForm.linkmanTel" />
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="customerRuleForm.address" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="coopCustomerVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="createData" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import business from "../../custom/components/business.vue";
import { addCustomer } from "../../../../api/crm";
export default {
  name: "addCoop",
  components: {
    business,
  },
  props: {},
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号！"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号！"));
      } else {
        return callback();
      }
    };
    return {
      coopCustomerVisible: false,
      customerRuleForm: {
        customerName: "",
        userId: [],
        linkman: "",
        linkmanTel: "",
        address: "",
        customerType: "2",
      },
      rules: {
        customerName: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        userId: [
          { required: true, message: "请选择业务负责人", trigger: "change" },
        ],
        linkman: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        linkmanTel: [
          { required: true, validator: validateMobile, trigger: "blur" },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
      },
    };
  },
  methods: {
    init() {
      this.resetForm();
      this.coopCustomerVisible = true;
    },
    createData() {
      this.$refs["customerForm"].validate((valid) => {
        if (valid) {
          addCustomer(this.customerRuleForm).then((res) => {
            if (res.data.msg == "success") {
              this.coopCustomerVisible = false;
              this.$qzfMessage("创建成功");
              this.$emit("success", res.data.data.info);
            }
          });
        }
      });
    },
    resetForm() {
      this.customerRuleForm = {
        customerName: "",
        userId: [],
        linkman: "",
        linkmanTel: "",
        address: "",
        customerType: "2",
      };
    },
  },
};
</script>

<style scoped lang="scss">
.el-form-item {
  margin-bottom: 22px !important;
}
</style>
