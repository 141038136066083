<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="新增合同"
    width="815px"
  >
    <el-scrollbar :maxHeight="contentStyleObj">
      <div style="padding-right: 15px">
        <div class="record">基础信息：</div>
        <el-form
          :inline="true"
          style="width: 100%"
          label-width="95px"
          :rules="rules"
          :model="form"
          ref="contractForm"
        >
          <el-form-item
            label="公司名称："
            @click.prevent="() => {}"
            prop="customerId"
          >
            <selectCustomer
              @success="getInfo"
              ref="selectCustomer"
              :list="customerOptions"
              v-model:userId="form.customerId"
              style="width: 268px; margin: 0"
            />
          </el-form-item>
          <el-form-item label="合同编号：" @click.prevent="() => {}">
            <el-input
              placeholder="请输入合同编号"
              v-model="form.contractNum"
              size="small"
              style="width: 268px"
            />
          </el-form-item>
          <el-form-item
            label="客户来源："
            @click.prevent="() => {}"
            prop="customerSource"
          >
            <selectSource
              v-model:sourceId="form.customerSourceId"
              style="width: 268px; margin: 0"
            />
          </el-form-item>
          <el-form-item
            label="联系人："
            @click.prevent="() => {}"
            prop="contactsName"
          >
            <el-input
              placeholder="请输入联系人"
              v-model="form.contactsName"
              size="small"
              style="width: 268px"
            />
          </el-form-item>
          <el-form-item
            label="销售经理："
            @click.prevent="() => {}"
            prop="userId"
          >
            <business
              code="coopCustom"
              v-model:userId="form.userId"
              style="margin: 0; width: 268px"
            ></business>
          </el-form-item>
          <el-form-item
            label="联系电话："
            @click.prevent="() => {}"
            prop="contactsPhone"
          >
            <el-input
              placeholder="请输入联系电话"
              v-model="form.contactsPhone"
              size="small"
              style="width: 268px"
            />
          </el-form-item>
          <el-form-item label="签约时间：" @click.prevent="() => {}">
            <el-date-picker
              v-model="form.contractSignTime"
              type="date"
              placeholder="请选择签约时间"
              style="width: 268px"
              value-format="YYYY-MM-DD"
              size="small"
            />
          </el-form-item>
          <el-form-item label="收款方式：" @click.prevent="() => {}">
            <selectMethod
              v-model:methodId="form.paymentMethodId"
              style="width: 268px; margin: 0"
            />
          </el-form-item>
          <div>
            <el-form-item label="合同备注：" @click.prevent="() => {}">
              <el-input
                placeholder="合同备注"
                v-model="form.remark"
                size="small"
                type="textarea"
                style="width: 662px"
              />
            </el-form-item>
          </div>
        </el-form>
        <div class="record" style="float: left">
          <span style="color: red">*</span>服务项目：
        </div>
        <div
          style="
            float: right;
            color: var(--themeColor, #17a2b8);
            cursor: pointer;
          "
          @click="addFwxm(form.server)"
        >
          +添加服务项目
        </div>
        <div>
          <table class="content" cellspacing="0" width="100%">
            <tr class="top_bg center">
              <td style="width: 18%">服务项目</td>
              <td style="width: 18%">付款周期</td>
              <td style="width: 18%">开始时间</td>
              <td style="width: 18%">结束时间</td>
              <td style="width: 18%">金额</td>
              <td style="width: 10%">操作</td>
            </tr>
            <tr
              class="center"
              v-for="(item, index) in form.server"
              :key="index"
            >
              <td style="width: 18%">
                <selectBusiness
                  v-model:category="item.categoryNameId"
                  size="small"
                  style="width: 100%"
                  @success="getOptions"
                  @addBussiness="getCategory"
                ></selectBusiness>
              </td>
              <td style="width: 18%">
                <el-select
                  v-model="item.disposable"
                  placeholder="请选择付款周期"
                  size="small"
                  style="width: 100px"
                  @change="changeDisable(item)"
                >
                  <el-option label="周期性" value="0" />
                  <el-option label="一次性" value="1" />
                </el-select>
              </td>
              <td style="width: 18%">
                <el-date-picker
                  type="date"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择开始时间"
                  size="small"
                  style="width: 100%"
                  v-model="item.contractStartTime"
                  :disabled="item.disposable == '1'"
                />
              </td>
              <td style="width: 18%">
                <el-date-picker
                  type="date"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择结束时间"
                  size="small"
                  style="width: 100%"
                  v-model="item.contractEndTime"
                  :disabled="item.disposable == '1'"
                />
              </td>
              <td style="width: 18%">
                <el-input
                  v-model="item.contractAmount"
                  size="small"
                  style="width: 100%"
                  placeholder="请输入合同金额"
                />
              </td>
              <td style="width: 10%">
                <span
                  style="color: red; cursor: pointer"
                  @click="delFwxm(form.server, index)"
                  >删除</span
                >
              </td>
            </tr>
            <tr>
              <td style="text-align: right" colspan="6">
                合同金额：¥{{ totalAmount1 }}
              </td>
            </tr>
          </table>
        </div>
        <div class="record" style="float: left; margin-top: 10px">
          合同收款：
        </div>
        <div
          style="
            float: right;
            color: var(--themeColor, #17a2b8);
            cursor: pointer;
            margin-top: 10px;
          "
          @click="addSkxx(form.records)"
          v-if="totalAmount3 != 0"
        >
          +添加收款信息
        </div>
        <div>
          <table class="content" cellspacing="0" width="100%">
            <tr class="top_bg center">
              <td style="width: 18%">收款时间</td>
              <td style="width: 18%">服务项目</td>
              <td style="width: 18%">收款期起</td>
              <td style="width: 18%">收款期止</td>
              <td style="width: 18%">收款金额</td>
              <td style="width: 10%">操作</td>
            </tr>
            <tr
              class="center"
              v-for="(item, index) in form.records"
              :key="index"
            >
              <td style="width: 18%">
                <el-date-picker
                  type="date"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择收款时间"
                  size="small"
                  style="width: 100%"
                  v-model="item.paymentTime"
                />
              </td>
              <td style="width: 18%">
                <collectionBusiness
                  v-model:typeId="item.categoryNameId"
                  size="small"
                  style="width: 100%"
                  ref="collectionBusiness"
                  :options="options"
                  @success="changeCategory(item)"
                />
              </td>
              <td style="width: 18%">
                <el-date-picker
                  type="date"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择收款期起"
                  size="small"
                  style="width: 100%"
                  v-model="item.collectionStartTime"
                  :disabled="item.status"
                />
              </td>
              <td style="width: 18%">
                <el-date-picker
                  type="date"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择收款期止"
                  size="small"
                  style="width: 100%"
                  v-model="item.collectionEndTime"
                  :disabled="item.status"
                />
              </td>
              <td style="width: 18%">
                <el-input
                  v-model="item.paymentAmount"
                  size="small"
                  style="width: 100%"
                />
              </td>
              <td style="width: 10%">
                <span
                  style="color: red; cursor: pointer"
                  @click="delSkxx(form.records, index)"
                  >删除</span
                >
              </td>
            </tr>
            <tr>
              <td style="text-align: right" colspan="6">
                收款金额：¥{{ totalAmount2 }} 未付款：¥{{ totalAmount3 }}
              </td>
            </tr>
          </table>
        </div>
        <div class="record" style="margin-top: 10px">合同附件：</div>
        <div>
          <div class="archives_up">
            <div
              class="pic"
              v-for="(item, index) in img"
              :key="index"
              v-show="img.length != 0"
            >
              <img
                :src="item"
                style="height: 80px; margin-right: 5px"
                @click="checkImg(item)"
              />
              <a class="del_btn" @click="delImg(index)">x</a>
            </div>
          </div>
          <div class="bigimg" style="margin-left: 0">
            <el-button type="primary" size="small"
              ><input
                @change="uploadimg($event)"
                class="inputfl"
                type="file"
                name="upFile"
                id=""
              />上传图片</el-button
            >
          </div>
        </div>
        <div class="record" style="margin-top: 10px">文件上传：</div>
        <div>
          <el-input
            style="width: 50%; margin-right: 10px"
            v-model="path"
            size="small"
          ></el-input>
          <input
            size="small"
            ref="excel-upload-input"
            class="excel-upload-input"
            style="display: none"
            type="file"
            @change="handleClick"
          />
          <el-button type="primary" @click="handleUpload()" size="small"
            >请选择文件</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="uploadExcel"
            v-loading="loading"
            >上传</el-button
          >
        </div>
        <!-- <upload @uploadimgzj="imgUrl" style="margin-left:0" /> -->
      </div>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="saveContract()"
          size="small"
          :loading="btnLoading"
        >
          确 定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 图片预览 -->
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="showImg"
  />
</template>

<script>
import axios from "axios";
import {
  categoryListNew,
  saveContractNew,
  getCustomerList,
  customerInfo,
} from "@/api/crm";
import selectBusiness from "../../components/selectBusiness.vue";
import upload from "@/components/uploadImg/upload";
import business from "./business.vue";
import selectSource from "./selectSource.vue";
import selectCustomer from "../../contract/components/selectCustomer.vue";
import collectionBusiness from "./collectionBusiness.vue";
import selectMethod from "./selectMethod.vue";
export default {
  name: "",
  components: {
    selectBusiness,
    upload,
    business,
    selectSource,
    selectCustomer,
    collectionBusiness,
    selectMethod,
  },
  props: {},
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号！"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号！"));
      } else {
        return callback();
      }
    };
    return {
      dialogFormVisible: false,
      form: {
        id: null,
        customerId: null,
        customerName: "",
        contractNum: "",
        contactsName: "",
        userId: null,
        contactsPhone: "",
        contractSignTime: "",
        customerSource: "",
        remark: "",
        paymentMethodId: null,
        contractFile: "",
        server: [
          {
            categoryNameId: null,
            disposable: "1",
            contractStartTime: "",
            contractEndTime: "",
            contractAmount: "",
          },
        ],
        records: [
          // {
          //   categoryNameId: null,
          //   collectionStartTime: "",
          //   collectionEndTime: "",
          //   paymentAmount: "",
          //   paymentTime: "",
          // },
        ],
        contractImg: "",
        contractImgs: [],
      },
      rules: {
        customerId: [
          {
            required: true,
            message: "请选择公司",
            trigger: "change",
          },
        ],
        contactsName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        userId: [
          {
            required: true,
            message: "请选择销售经理",
            trigger: "change",
          },
        ],
        contactsPhone: [
          { required: true, validator: validateMobile, trigger: "blur" },
        ],
      },
      contentStyleObj: {},
      totalAmount1: 0,
      totalAmount2: 0,
      totalAmount3: 0,
      sourceOption: [],
      customerOptions: [],
      date: "",
      options: [],
      categoryList: [],
      btnLoading: false,
      img: [],
      showImagePreview: false,
      showImg: [],
      path: "",
      rawFile: "",
    };
  },
  computed: {
    //金额合计
    totalAmount1() {
      let amount = 0;
      this.form.server.map((v) => {
        if (v.contractAmount) {
          amount += Number(v.contractAmount);
        }
      });
      return Number(amount.toFixed(2));
    },
    totalAmount2() {
      let amount = 0;
      this.form.records.map((v) => {
        if (v.paymentAmount) {
          amount += Number(v.paymentAmount);
        }
      });
      return Number(amount.toFixed(2));
    },
    totalAmount3() {
      let amount = 0;
      let count1 = 0;
      let count2 = 0;
      this.form.server.map((v) => {
        if (v.contractAmount) {
          count1 += Number(v.contractAmount);
        }
      });
      this.form.records.map((v) => {
        if (v.paymentAmount) {
          count2 += Number(v.paymentAmount);
        }
      });
      if (count1 && count1 >= count2) amount = Number(count1 - count2);
      return Number(amount.toFixed(2));
    },
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(280);
    this.initBus();
    //this.getList();
  },
  methods: {
    initBus() {
      this.$bus.off("businessTypeUpdate");
      this.$bus.on("businessTypeUpdate", (val) => {
        this.getCategory();
      });
    },
    getCategory() {
      categoryListNew({}).then((res) => {
        if (res.data.msg == "success") {
          this.categoryList = res.data.data.list ? res.data.data.list : [];
          this.getOptions();
        }
      });
    },
    //查询合作客户列表
    getList() {
      getCustomerList({ customerType: "2" }).then((res) => {
        if (res.data.msg == "success") {
          this.customerOptions = res.data.data.list;
        }
      });
    },
    getDay() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day <= 9 ? "0" + day : day;
      this.form.contractSignTime = year + "-" + month + "-" + day;
      this.date = year + "-" + month + "-" + day;
    },
    init() {
      this.resetForm();
      this.img = [];
      this.getList();
      this.getDay();
      this.form.userId = [this.$store.getters["user/user"].userId];
      this.dialogFormVisible = true;
      this.getCategory();
      this.$nextTick(() => {
        this.$refs["contractForm"].clearValidate();
      });
    },
    //添加服务项目
    addFwxm(item) {
      item.push({
        categoryNameId: null,
        disposable: "1",
        contractStartTime: "",
        contractEndTime: "",
        contractAmount: "",
      });
    },
    //删除服务项目
    delFwxm(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    //添加收款信息
    addSkxx(item) {
      item.push({
        categoryNameId: null,
        collectionStartTime: "",
        collectionEndTime: "",
        paymentAmount: "",
        paymentTime: this.date,
      });
    },
    //删除收款信息
    delSkxx(item, index) {
      // if (item.length <= 1) {
      //   this.$message.error("仅剩一项禁止删除");
      //   return;
      // }
      item.splice(index, 1);
    },
    //获取图片
    imgUrl(val) {
      this.form.contractImg = val;
    },
    getOptions() {
      // 初始化 options
      this.options = [];

      // 确保 categoryList 和 form.server 都存在
      if (!this.categoryList || !this.form.server) return;

      // 创建一个映射表用于快速查找
      const serverMap = new Map();
      this.form.server.forEach((item) => {
        if (!item.id) {
          // 设置 disposable 属性
          item.disposable = item.categoryNameId === 5 ? "0" : "1";
        }
        // 使用 Map 存储 id 对应的 item
        serverMap.set(item.categoryNameId, item);
      });

      // 遍历 categoryList 并添加符合条件的子项
      this.categoryList.forEach((v) => {
        v.children.forEach((k) => {
          if (serverMap.has(k.id)) {
            this.options.push(k);
          }
        });
      });

      // 去重
      this.options = [...new Set(this.options.map((option) => option.id))].map(
        (id) => {
          return this.options.find((option) => option.id === id);
        }
      );
    },
    changeCategory(item) {
      this.form.server.map((v) => {
        if (v.categoryNameId == item.categoryNameId) {
          if (v.disposable == "0") {
            item.status = false;
            item.collectionStartTime = v.contractStartTime;
            item.collectionEndTime = v.contractEndTime;
          } else {
            item.status = true;
          }
        }
      });
    },
    changeDisable(item) {
      if (item.disposable == "1") {
        item.contractStartTime = "";
        item.contractEndTime = "";
        if (this.form.records && this.form.records.length != 0) {
          this.form.records.map((v) => {
            if (v.categoryNameId == item.categoryNameId) {
              v.status = true;
            }
          });
        }
      } else {
        if (this.form.records && this.form.records.length != 0) {
          this.form.records.map((v) => {
            if (v.categoryNameId == item.categoryNameId) {
              v.status = false;
            }
          });
        }
      }
    },
    saveContract() {
      this.$refs["contractForm"].validate((valid) => {
        if (valid) {
          const hasMissingCategory = (items, key) => items.some((item) => !item[key]); // 检查是否有未选择的项目
          const hasInvalidDates = (items) => items.some((item) => item.disposable === "0" &&(!item.contractStartTime || !item.contractEndTime)); // 检查是否有未填写的日期
          const hasMissingAmount = (items, key) => items.some((item) => !item[key] || item[key] === "0"); // 检查是否有未填写的收款金额
          const hasMissingContractAmount = (items, key) => items.some((item) => !item[key]); // 检查是否有未填写的合同金额
          if (hasMissingCategory(this.form.server, "categoryNameId")) {
            this.$qzfMessage("请选择服务项目", 1);
            return;
          }
          if (hasInvalidDates(this.form.server)) {
            this.$qzfMessage("存在周期性合同未选择时间", 1);
            return;
          }
          if (hasMissingContractAmount(this.form.server, "contractAmount")) {
            this.$qzfMessage("请输入合同金额", 1);
            return;
          }
          if (hasMissingCategory(this.form.records, "categoryNameId")) {
            this.$qzfMessage("存在收款信息未选择服务项目", 1);
            return;
          }
          if (hasMissingAmount(this.form.records, "paymentAmount")) {
            this.$qzfMessage("请输入收款金额", 1);
            return;
          }
          if (  this.form.records.some((v) => !v.id && !this.form.paymentMethodId) ) {
            this.$qzfMessage("检测到您新增收款信息，请选择收款方式！", 1);
            return;
          }

          this.btnLoading = true;
          this.form.auditStatus = 1;
          this.form.contractImgs = this.img;

          // 简化数值处理
          this.form.server.forEach((v) => {
            v.contractAmount = parseFloat(v.contractAmount) || 0;
          });

          this.form.records.forEach((v) => {
            v.paymentAmount = parseFloat(v.paymentAmount) || 0;
          });

          saveContractNew(this.form).then((res) => {
            this.btnLoading = false;
            if (res.data.msg === "success") {
              this.$qzfMessage("操作成功");
              this.dialogFormVisible = false;
              this.$emit("success");
            }
          });
        }
      });
    },
    getInfo() {
      customerInfo({ customerId: this.form.customerId }).then((res) => {
        if(res.data.msg == 'success'){
          this.form.contactsName = res.data.data.list.linkman;
          this.form.contactsPhone = res.data.data.list.linkmanTel;
          this.form.server[0].categoryNameId = res.data.data.list.categoryNameId
          this.getOptions()
        }
      });
    },
    //上传图片
    uploadimg(event) {
      if (event.target.files.length > 0) {
        this.files = event.target.files[0]; // 提交的图片
        this.addSub();
      }
    },
    addSub() {
      console.log(this.files);
      const files = this.files;
      const isLt1M = files.size / 1024 / 1024 < 10;
      if (!isLt1M) {
        this.$message({
          message: "请不要上传大于10M的文件。",
          type: "warning",
        });
        return;
      }
      const param = new FormData(); // 创建form对象
      if (files !== "") {
        param.append("upFile", files, files.name); // 单个图片 ，多个用循环 append 添加
      } else {
        this.$message.error("请添加图片");
      }
      console.log(param.toString());
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      axios
        .post("https://public.listensoft.net/api/uploadImg", param, config)
        .then((res) => {
          const imgs = res.data.data;
          this.img.push("https://file.listensoft.net" + imgs.url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除图片
    delImg(index) {
      this.img.splice(index, 1);
    },
    checkImg(item) {
      let arr = [];
      arr.push(item);
      this.img.map((v) => {
        if (v != item) {
          arr.push(v);
        }
      });
      this.showImg = arr;
      this.showImagePreview = true;
    },
    //上传文件
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      const files = this.rawFile;
      const isLt1M = files.size / 1024 / 1024 < 10;
      if (!isLt1M) {
        this.$qzfMessage("请不要上传大于10M的文件。", 1);
        return;
      }
      const param = new FormData(); // 创建form对象
      if (this.rawFile != "") {
        param.append("upFile", this.rawFile, this.rawFile.name);
      } else {
        this.$message.error("请选择文件");
        return;
      }
      this.loading = true;
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      axios
        .post("https://public.listensoft.net/api/uploadImg", param, config)
        .then((res) => {
          this.loading = false;
          if (res.data.msg == "success") {
            this.$qzfMessage("上传成功");
            this.form.contractFile =
              "https://file.listensoft.net" + res.data.data.url;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    resetForm() {
      this.form = {
        id: null,
        customerId: null,
        customerName: "",
        contractNum: "",
        contactsName: "",
        userId: null,
        contactsPhone: "",
        contractSignTime: "",
        customerSource: "",
        paymentMethodId: null,
        remark: "",
        contractFile: "",
        server: [
          {
            categoryNameId: null,
            disposable: "1",
            contractStartTime: "",
            contractEndTime: "",
            contractAmount: "",
          },
        ],
        records: [
          // {
          //   categoryNameId: null,
          //   collectionStartTime: "",
          //   collectionEndTime: "",
          //   paymentAmount: "",
          //   paymentTime: "",
          // },
        ],
        contractImg: "",
        contractImgs: [],
      };
      this.path = "";
      this.rawFile = "";
    },
  },
};
</script>

<style scoped lang="scss">
.record {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}
.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.el-form-item__label {
  font-size: 13px;
}
.content {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  span {
    line-height: 28px;
    font-size: 13px;
  }
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    line-height: 25px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
}
.center {
  text-align: center;
}
:deep(.el-textarea__inner) {
  min-height: 80px !important;
}
table {
  :deep(.el-input__wrapper) {
    box-shadow: none;
    background: none;
    border: none;
  }
}
.boxShadowNone2 {
  :deep(.el-select-v2__wrapper) {
    box-shadow: none;
    background: none;
    border: none;
  }
}
.el-form--inline .el-form-item:nth-child(2n) {
  margin-right: 0;
}
.el-form--inline .el-form-item:last-child {
  margin-right: 0;
}
.archives_up {
  width: 96%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bigimg {
  margin-top: 19px;
}
.bigimg button {
  transform: translateY(-10px);
  margin-left: 20px;
  cursor: pointer;
}
.bigimg input {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  // height: 35px;
}
.pic {
  display: flex;
}
.del_btn {
  position: relative;
  top: 0;
  padding-right: 10px;
  color: red;
  cursor: pointer;
}
</style>
