<template>
  <div>
    <div class="select">
      <div class="every_select">
        <el-button
          icon="RefreshRight"
          size="small"
          @click="getList()"
        ></el-button>
        <el-select
          v-model="listQuery.auditStatus"
          placeholder="请选择"
          style="width: 120px"
          size="small"
          @change="getList"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option label="未审核合同" :value="1"></el-option>
          <el-option label="驳回合同" :value="2"></el-option>
        </el-select>
        <el-input
          placeholder="请输入公司名称"
          v-model="listQuery.customerName"
          style="width: 200px; margin-right: 5px"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-button type="primary" @click="getList()" size="small" icon="Search"
          >搜索</el-button
        >
      </div>
      <div>
        <colSetting
          name="合同审核"
          btnName="显示列"
          @arrangeInfo="getInfo"
          @load="loadingV"
        />
        <el-button
          v-if="$buttonStatus('hzkh_htshtg') && listQuery.auditStatus == 1"
          type="success"
          size="small"
          :disabled="this.sels.length === 0"
          @click="startsAll()"
          plain
          icon="Select"
          >批量审核通过</el-button
        >
        <el-button
          type="primary"
          plain
          size="small"
          icon="FolderOpened"
          @click="daochu"
          :loading="daochuLoading"
          :disabled="!$buttonStatus('hzkh_htdc')"
          >导出</el-button
        >
        <el-button
          type="danger"
          plain
          size="small"
          icon="Delete"
          @click="delAll"
          :disabled="this.sels.length === 0"
          :loading="delLoading"
          v-if="isAdmin == 1"
          >删除</el-button
        >
      </div>
    </div>
    <el-table
      :data="startsList"
      style="margin-top: 10px"
      border
      :height="contentStyleObj"
      @selection-change="handleSelectContract"
      v-loading="listLoading"
      stripe
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center" fixed />
      <el-table-column
        type="index"
        label="序号"
        width="70"
        align="center"
        fixed
      />
      <el-table-column
        prop="customerName"
        label="公司名称"
        align="left"
        fixed
        min-width="250"
        v-if="arrangeInfo.includes('公司名称')"
      >
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <div
              class="item_icon"
              style="
                width: 95%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              <span>{{ scope.row.customerName }}</span>
            </div>
            <el-tooltip
              content="复制"
              placement="top"
              v-if="scope.row.customerName"
            >
              <i
                class="iconfont icon-fuzhi1"
                style="color: var(--themeColor, #17a2b8); cursor: pointer"
                @click="$copyComName(scope.row.customerName)"
              >
              </i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="contractNum"
        label="合同编号"
        min-width="120"
        align="left"
        v-if="arrangeInfo.includes('合同编号')"
      />
      <el-table-column
        prop="contactsName"
        label="联系人"
        min-width="80"
        align="left"
        v-if="arrangeInfo.includes('联系人')"
      />
      <el-table-column
        prop="contactsPhone"
        label="联系电话"
        min-width="125"
        align="left"
        v-if="arrangeInfo.includes('联系电话')"
      >
      </el-table-column>
      <el-table-column
        prop="remark"
        label="合同状态"
        width="100"
        align="center"
      >
        <template #default="scope">
          <div class="item_icon" v-if="scope.row.auditStatus == 2">
            <p>
              <i class="iconfont icon-cuowu"></i>
              <span class="wsb">驳回</span>
            </p>
          </div>
          <div class="item_icon" v-if="scope.row.auditStatus == 1">
            <p>
              <i class="iconfont icon-gantanhao"></i>
              <span class="wsb">未审核</span>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="categoryType"
        label="服务项目"
        min-width="150"
        align="left"
      >
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.server"
            :key="index"
          >
            <p style="text-align: left">{{ item.categoryName }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="contractStartTime"
        label="合同开始时间"
        width="130"
        align="left"
        v-if="arrangeInfo.includes('合同开始时间')"
      >
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.server"
            :key="index"
          >
            <p style="text-align: left">{{ item.contractStartTime }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="contractEndTime"
        label="合同结束时间"
        width="130"
        align="left"
        v-if="arrangeInfo.includes('合同结束时间')"
      >
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.server"
            :key="index"
          >
            <p style="text-align: left">{{ item.contractEndTime }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="contractAmount1"
        label="合同金额"
        min-width="120"
        align="left"
        v-if="arrangeInfo.includes('合同金额')"
      >
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.server"
            :key="index"
          >
            <p style="text-align: left">{{ item.contractAmount1 }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="paymentAmount1"
        label="付款金额"
        min-width="120"
        align="left"
        v-if="arrangeInfo.includes('付款金额')"
      >
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.records"
            :key="index"
          >
            <p style="text-align: left">{{ item.paymentAmount1 }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="contractSignTime"
        label="合同签订时间"
        width="130"
        align="left"
        v-if="arrangeInfo.includes('合同签订时间')"
      />
      <el-table-column
        prop="disCodes"
        label="销售经理"
        min-width="120"
        align="left"
        show-overflow-tooltip
        v-if="arrangeInfo.includes('销售经理')"
      />
      <el-table-column
        prop="contractImg"
        label="附件"
        width="70"
        align="center"
      >
        <template #default="scope">
          <el-tooltip content="点击下载文件附件" placement="top" effect="dark">
            <i
              class="iconfont icon-wenjianleixing-biaozhuntu-wenjianjia"
              v-if="scope.row.contractFile"
              style="font-size: 16px; color: orange; cursor: pointer"
              @click="openFile(scope.row.contractFile)"
            ></i>
          </el-tooltip>
          <el-tooltip content="点击查看图片附件" placement="top" effect="dark">
            <i
              class="iconfont icon-picture"
              v-if="scope.row.contractImg"
              style="
                font-size: 16px;
                color: var(--themeColor, #17a2b8);
                cursor: pointer;
                margin-left: 5px;
              "
              @click="open(scope.row)"
            ></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        width="150"
        align="center"
        v-if="arrangeInfo.includes('备注')"
      />
      <el-table-column
        label="操作"
        align="center"
        min-width="360"
        fixed="right"
      >
        <template #default="scope">
          <el-button
            :disabled="!$buttonStatus('hzkh_htbj')"
            type="primary"
            plain
            size="small"
            @click="handleContract(scope.row)"
            icon="Edit"
            >修改</el-button
          >
          <el-button
            :disabled="!$buttonStatus('hzkh_htbh')"
            type="danger"
            size="small"
            @click="handleReject(scope.row)"
            plain
            icon="Warning"
            v-if="scope.row.auditStatus == 1"
            :loading="scope.row.loading"
            >驳回</el-button
          >

          <el-button
            :disabled="!$buttonStatus('hzkh_htshtg')"
            type="success"
            size="small"
            plain
            @click="updateContractStarts(scope.row)"
            icon="Select"
            v-if="scope.row.auditStatus == 1"
            >审核通过</el-button
          >
          <el-button
            :disabled="!$buttonStatus('hzkh_htsc')"
            type="danger"
            size="small"
            @click="delContract(scope.row)"
            plain
            v-if="isAdmin == 1"
            icon="Delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
        type="coopCustom"
      />
    </div>
    <!-- 驳回弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="rejectFormVisible"
      title="驳回"
    >
      <div>
        <span>驳回原因：</span>
        <el-input
          v-model="rejectForm.remark"
          :rows="2"
          type="textarea"
          placeholder="请输入"
          style="margin-top: 20px"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="rejectFormVisible = false" size="small"
            >取消</el-button
          >
          <el-button type="primary" @click="rejected()" size="small">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <signContract ref="signContract" @success="getList" />
  <!-- 图片预览 -->
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="imgSrc"
  />
</template>

<script>
import {
  delContract,
  saveContractStatus,
  contractListNew,
  batchDelContractNew,
  saveContractNew,
  exportContractNew,
  contractInfo
} from "@/api/crm";
import selectBusiness from "../../components/selectBusiness.vue";
import business from "./business.vue";
import signContract from "./signContract.vue";
function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = deepClone(obj[key]);
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}
export default {
  name: "contractExamine",
  components: {
    selectBusiness,
    business,
    signContract,
  },
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号！"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号！"));
      } else {
        return callback();
      }
    };
    return {
      isAdmin: this.$store.getters["user/user"].isAdmin,
      isLeader: this.$store.getters["user/user"].isLeader,
      listLoading: false,
      sels: [],
      total: "",
      rejectFormVisible: false,
      listQuery: {
        limit: 20,
        page: 1,
        auditStatus: 1,
        customerName: null,
      },
      rejectForm: {
        auditStatus: 2,
        id: "",
        remark: "",
      },
      startsList: [],
      contractDialogVisible: false,
      contractForm: {
        contractNum: "",
        category: [],
        categoryType: "",
        categoryName: "",
        contractStartTime: "",
        contractEndTime: "",
        contractRenewTime: "",
        contractAmount: "",
        contractActualAmount: "",
        contactsName: "",
        contactsPhone: "",
        businessName: "",
        disposable: false,
        remark: "",
        id: "",
        auditStatus: null,
        userId: [],
      },
      rules: {
        contractNum: [
          { required: true, message: "请输入合同编码", trigger: "blur" },
        ],
        category: [
          { required: true, message: "请选择业务名称", trigger: "change" },
        ],
        contractStartTime: [
          { required: true, message: "请选择合同开始时间", trigger: "change" },
        ],
        contractEndTime: [
          { required: true, message: "请选择合同结束时间", trigger: "change" },
        ],
        contractAmount: [
          { required: true, message: "请输入合同金额", trigger: "blur" },
        ],
        contractActualAmount: [
          { required: true, message: "请输入实收金额", trigger: "blur" },
        ],
        contactsName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        userId: [
          {
            required: true,
            message: "请选择业务负责人",
            trigger: "change",
          },
        ],
        disposable: [
          { required: true, message: "请选择是或否", trigger: "change" },
        ],
        contactsPhone: [
          { required: true, validator: validateMobile, trigger: "blur" },
        ],
      },
      arrangeInfo: [
        "公司名称",
        "合同编号",
        "联系人",
        "联系电话",
        "合同签订时间",
        "服务项目",
        "合同开始时间",
        "合同结束时间",
        "合同金额",
        "付款金额",
        "销售经理",
        "备注",
      ],
      showImagePreview: false,
      imgSrc: [],
      daochuLoading: false,
      delLoading:false
    };
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem("coopCustom")
      ? localStorage.getItem("coopCustom") * 1
      : 20;
    this.contentStyleObj = this.$getHeight(270);
    this.getList();
  },

  methods: {
    getInfo(e) {
      this.arrangeInfo = e;
    },
    getList() {
      this.listLoading = true;
      contractListNew(this.listQuery).then((res) => {
        this.listLoading = false;
        if (res.data.msg == "success") {
          this.startsList = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectContract(sels) {
      this.sels = sels;
    },
    //一次性变化设置
    handleDisposable(val) {
      if (val == 0) {
        this.contractForm.contractRenewTime = this.contractForm.contractEndTime;
      }
      if (val == 1) {
        this.contractForm.contractRenewTime = "";
      }
    },
    //修改合同
    handleContract(row) {
      this.$refs.signContract.init(row, "update");
    },
    //删除合同
    delContract(row) {
      this.$confirm("确认删除合同吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          batchDelContractNew([{ id: row.id }]).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("删除成功");
              this.getList();
              this.$bus.emit("contractUpdate");
            }
          });
        })
        .catch(() => {});
    },
    //批量删除合同
    delAll() {
      this.$confirm("确认批量删除合同吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delLoading = true;
          let ids = [];
          this.sels.map((item) => {
            ids.push({
              id: item.id,
            });
          });
          batchDelContractNew(ids).then((res) => {
            this.delLoading = false
            if (res.data.msg == "success") {
              this.$qzfMessage("删除成功");
              this.getList();
              this.$bus.emit("contractUpdate");
            }
          });
        })
        .catch(() => {});
    },
    //单独审核通过
    updateContractStarts(row) {
      this.$confirm("确认通过审核吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          saveContractStatus([{ id: row.id, auditStatus: 3 }]).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("修改成功");
              this.getList();
              this.$bus.emit("contractUpdate");
            }
          });
        })
        .catch(() => {});
    },
    //批量审核通过
    startsAll() {
      this.$confirm("确认全部通过审核吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = [];
          this.sels.map((item) => {
            ids.push({
              id: item.id,
              auditStatus: 3,
            });
          });
          saveContractStatus(ids).then((res) => {
            if (res.data.msg == "success") {
              this.getList();
              this.$bus.emit("contractUpdate");
              this.$qzfMessage("修改成功");
            }
          });
        })
        .catch(() => {});
    },
    //合同驳回
    handleReject(row) {
      row.loading = true
      contractInfo({id:row.id}).then(res=>{
        row.loading = false
        if(res.data.msg=="success"){
          this.rejectForm = deepClone(res.data.data.list);
          this.rejectForm.remark = "";
          this.rejectForm.auditStatus = 2;
          this.rejectFormVisible = true;
        }
      })
    },
    rejected() {
      saveContractNew(this.rejectForm).then((res) => {
        if (res.data.msg == "success") {
          this.getList();
          this.$bus.emit("contractUpdate");
          this.$qzfMessage("驳回成功");
          this.rejectFormVisible = false;
        }
      });
    },
    // 样式
    tableRowClassName({ row, rowIndex }) {
      if (row.auditStatus == 2) {
        return "warning-row";
      }
      return "";
    },
    open(row) {
      this.showImagePreview = true;
      this.imgSrc = row.contractImgs;
    },
    openFile(item) {
      window.open(item);
    },
    //导出
    daochu() {
      this.daochuLoading = true;
      let ids = [];
      this.sels.map((v) => {
        ids.push(v.id);
      });
      let param = {
        ids: ids,
        query: this.listQuery,
      };
      exportContractNew(param).then((res) => {
        this.daochuLoading = false;
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  display: flex;
  justify-content: space-between;
}
.el-form-item {
  width: 46% !important;
}
.item_icon {
  display: inline-block;
  // padding: 1px 12px;
  padding-right: 5px;
  cursor: pointer;
  i {
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 4px;
  }
}
.div_p {
  line-height: 23px;
  border-bottom: 1px solid #efefef;
  height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child {
    border-bottom: none;
  }
}
</style>
<style>
.el-table .warning-row {
  background: #fef0f0;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
