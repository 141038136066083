<template>
  <el-select
    style="width:268px;margin: 0;"
    filterable
    v-model="id"
    placeholder="请选择客户"
    clearable
    @change="changeValue"
    size="small"
  >
    <!-- <el-option label="全部" value="all" @click="addCoopCustomer">
      <span style="color:red">新增+</span>
    </el-option> -->
    <el-option
      v-for="(item, index) in this.list"
      :key="index"
      :label="item.customerName"
      :value="item.id"
    ></el-option>
  </el-select>
  <addCoop ref="addCoop" @success="updateCoop" />
</template>
<script>
import addCoop from "./addCoop.vue";
export default {
  components: {
    addCoop,
  },
  props: {
    list: {
      type: Array,
      default: [],
    },
    userId: {
      type: Number,
      default: 0,
    },
  },
  created() {},
  data() {
    return {};
  },
  computed: {
    id: {
      get() {
        if (!this.userId) {
          return "";
        }
        return this.userId;
      },
      set(val) {
        this.$emit("update:userId", val * 1);
        this.$emit("success", val * 1);
      },
    },
  },
  methods: {
    addCoopCustomer() {
      this.$refs.addCoop.init();
    },
    updateCoop(row) {
      this.id = row.id;
      this.$emit("success2");
    },
    change() {},
  },
};
</script>
