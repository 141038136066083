<template>
  <!-- <el-cascader
    @change="changeValue"
    v-model="citys"
    placeholder="所属区域"
    :options="list"
    filterable
    clearable
    size="small"
  ></el-cascader> -->
  <el-select :disabled="status" @change="changeValue" v-model="citys" placeholder="所属税务总局" size="small" clearable filterable style="width: 110px;">
    <el-option
      v-for="item in list"
      :key="item.addressCode"
      :label="item.name"
      :value="item.addressCode" >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'selectcity',
  props: {
    citys:'',
    status: false,
    // city: ""
  },
  data() {
    return {
      //list:this.$store.getters['inits/dis'],
    }
  },
  computed: {
    list() {
      return this.$store.getters['inits/dis']
    }
  },
  methods: {
    changeValue() {
      this.$emit('update:citys', this.citys)
      this.$emit("success",this.citys)
      // if(this.citys){
      //   this.$emit('update:citys', this.citys[this.citys.length - 1])
      // }else{
      //   this.$emit('update:city', "")
      // }
      // this.$emit("success", this.citys)
    },
    
  }
}
</script>

<style scoped>
  
</style>