<template>
  <div class="main">
    <!-- 查看小记弹窗 -->
    <el-dialog :close-on-click-modal="false" v-model="checkNote" title="客户小计" >
      <div class="note_box">
        <el-scrollbar height="500px">
          <div
            class="note_content"
            v-for="(item, index) in recordList"
            :key="index"
          >
            <div style="width: 80%">
              <div class="time">时间：{{ item.recordTime }}</div>
              <div class="note">小计：{{ item.archivesDescribe }}</div>
            </div>
            <div>
              <el-button type="danger" plain size="mini" @click="delNote(index)"
                >删除</el-button
              >
            </div>
          </div>
        </el-scrollbar>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="checkNote = false" size="small">关闭</el-button>
          <el-button type="primary" @click="addNote = true" size="small">
            添加小记
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 新增小记弹窗 -->
    <el-dialog :close-on-click-modal="false" v-model="addNote" title="添加客户小计" width="40%">
      <div style="height: 300px">
        <el-input
          v-model="note"
          :rows="4"
          type="textarea"
          placeholder="请输入小计内容"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addNote = false" size="small">取消</el-button>
          <el-button type="primary" @click="saveNote()" size="small"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getRecodeList, saveRecord, delRecode } from "../../../../api/crm";
export default {
  name: "noteDialog",
  components: {},
  props: {},
  data() {
    return {
      note: "",
      checkNote: false,
      addNote: false,
      recordList: [],
      noteForm: {
        recordType: "1",
      },
    };
  },
  methods: {
    //获取客户小计
    getNoteList(row) {
      if (row) {
        this.noteForm.customerId = row.id;
      }
      this.checkNote = true;
      getRecodeList(this.noteForm)
        .then((res) => {
          if (res.data.msg == "success") {
            this.recordList = res.data.data.list;
            //console.log(this.recordList);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    //删除小计
    delNote(index) {
      this.$confirm("确定删除小计吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delRecode({
          recordId: this.recordList[index].recordId,
          id: this.recordList[index].id,
        })
          .then((res) => {
            if (res.data.msg == "success") {
              this.$message({
                type: "success",
                message: "删除成功",
              });
            }
            this.getNoteList();
          })
          .catch((err) => {
            //console.log(err);
          });
      });
    },
    //添加小计
    saveNote() {
      saveRecord({
        customerId: this.noteForm.customerId,
        archivesDescribe: this.note,
        recordType: "1",
      })
        .then((res) => {
          if (res.data.msg == "success") {
            this.addNote = false;
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.getNoteList();
            this.note = ""
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="less">
.note_box {
  height: 500px;
}
.note_content {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: flex-end;
  padding-bottom: 20px;
}
.time {
  font-size: 18px;
}
.note {
  font-size: 14px;
  margin-top: 5px;
}
</style>
