<!-- 续约合同弹窗 -->
<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="renewContractVisible"
    title="续约"
  >
    <el-form
      ref="contractRuleFormRef"
      :model="renewForm"
      :rules="rules"
      label-width="120px"
      status-icon
      size="small"
    >
      <el-form-item label="公司名称" prop="customerId">
        <el-select
          placeholder="请选择"
          v-model="renewForm.customerId"
          filterable
          @change="handleSelect"
        >
          <el-option
            v-for="item in customerOptions"
            :key="item.id"
            :label="item.customerName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="原合同" prop="id">
        <el-select
          placeholder="请选择"
          v-model="renewForm.id"
          @change="getStartTime"
          filterable
        >
          <el-option
            v-for="item in contractOptions"
            :key="item.id"
            :label="item.contractNum"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="业务名称" prop="category">
        <selectBusiness v-model:category="renewForm.category"></selectBusiness>
      </el-form-item>
      <el-form-item label="合同签订日期">
        <el-date-picker
          v-model="renewForm.contractSignTime"
          type="date"
          label="请选择"
          placeholder="请选择"
          style="width: 100%"
          value-format="YYYY-MM-DD"
        />
      </el-form-item>
      <el-form-item label="合同开始时间" prop="contractStartTime">
        <el-date-picker
          v-model="renewForm.contractStartTime"
          type="date"
          label="请选择"
          placeholder="请选择"
          style="width: 100%"
          value-format="YYYY-MM-DD"
          disabled
        />
      </el-form-item>
      <el-form-item label="合同结束时间" prop="contractEndTime">
        <el-date-picker
          v-model="renewForm.contractEndTime"
          type="date"
          label="请选择"
          placeholder="请选择"
          style="width: 100%"
          value-format="YYYY-MM-DD"
        />
      </el-form-item>
      <el-form-item label="续费日期">
        <el-date-picker
          v-model="renewForm.contractRenewTime"
          type="date"
          label="请选择"
          placeholder="请选择"
          style="width: 100%"
          value-format="YYYY-MM-DD"
        />
      </el-form-item>
      <el-form-item label="合同金额">
        <el-input v-model="renewForm.contractAmount" />
      </el-form-item>
      <el-form-item label="实收金额">
        <el-input v-model="renewForm.contractActualAmount" />
      </el-form-item>
      <el-form-item label="联系人">
        <el-input v-model="renewForm.contactsName" />
      </el-form-item>
      <el-form-item label="联系电话" prop="contactsPhone">
        <el-input v-model="renewForm.contactsPhone" />
      </el-form-item>
      <el-form-item label="业务负责人" prop="userId">
        <business
          code="coopCustom"
          v-model:userId="renewForm.userId"
        ></business>
      </el-form-item>
      <el-form-item label="是否一次性" prop="disposable">
        <el-radio-group
          v-model="renewForm.disposable"
          @change="handleDisposable"
        >
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="renewForm.remark" type="textarea" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="renewContractVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="submit()" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  getCustomerList,
  getContractList,
  renewalContract,
} from "../../../../api/crm";
import business from "../../custom/components/business.vue";
import selectBusiness from "../../components/selectBusiness.vue";
export default {
  name: "renewContractDialog",
  components: {
    selectBusiness,
    business,
  },
  props: {},
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号！"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号！"));
      } else {
        return callback();
      }
    };
    return {
      renewContractVisible: false,
      customerOptions: [],
      contractOptions: [],
      renewForm: {
        id: null,
        category: "",
        categoryType: "",
        categoryName: "",
        contractStartTime: "",
        contractEndTime: "",
        contractRenewTime: "",
        contractAmount: "",
        contractActualAmount: "",
        contactsName: "",
        contactsPhone: "",
        userId: [],
        disposable: false,
        remark: "",
        customerId: "",
        auditStatus: null,
        contractSignTime: "",
      },
      rules: {
        contractStartTime: [
          { required: true, message: "请选择合同开始时间", trigger: "change" },
        ],
        contractEndTime: [
          { required: true, message: "请选择合同结束时间", trigger: "change" },
        ],
        id: [{ required: true, message: "请选择公司名称", trigger: "change" }],
        category: [
          { required: true, message: "请选择业务名称", trigger: "change" },
        ],
        userId: [
          {
            required: true,
            message: "请选择业务负责人",
            trigger: "change",
          },
        ],
        disposable: [
          { required: true, message: "请选择是或否", trigger: "change" },
        ],
        contactsPhone: [
          { required: true, validator: validateMobile, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    getList() {
      this.resetForm();
      this.contractOptions = [];
      this.renewContractVisible = true;
      if (this.customerOptions.length == 0) {
        getCustomerList({ customerType: "2" }).then((res) => {
          if (res.data.msg == "success") {
            res.data.data.list.map((item) => {
              this.customerOptions.push({
                id: item.id,
                customerName: item.customerName,
              });
            });
          }
        });
      }
      this.$nextTick(() => {
        this.$refs["contractRuleFormRef"].clearValidate();
      });
    },
    //一次性变化设置
    handleDisposable(val) {
      if (val == 0) {
        this.renewForm.contractRenewTime = this.renewForm.contractEndTime;
      }
      if (val == 1) {
        this.renewForm.contractRenewTime = "";
      }
    },
    //查询可续约合同
    handleSelect(e) {
      this.renewForm.id = "";
      this.renewForm.contractStartTime = "";
      getContractList({
        customerId: e,
        disposable: "0",
        auditStatus: 3,
        contractState: 1,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.contractOptions = res.data.data.list;
        }
      });
    },
    getStartTime(e) {
      getContractList({ id: e, auditStatus: 3 }).then((res) => {
        if (res.data.msg == "success") {
          this.renewForm.contractStartTime =
            res.data.data.list[0].contractEndTime;
        }
      });
    },
    submit() {
      this.$refs["contractRuleFormRef"].validate((valid) => {
        if (valid) {
          this.renewForm.auditStatus = 3;
          this.renewForm.contractAmount =
            (this.renewForm.contractAmount * 1).toFixed(2) * 1;
          this.renewForm.contractActualAmount =
            (this.renewForm.contractActualAmount * 1).toFixed(2) * 1;
          this.renewForm.categoryType = this.renewForm.category[0];
          this.renewForm.categoryName = this.renewForm.category[1];
          renewalContract(this.renewForm).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("续约成功");
              this.renewContractVisible = false;
              this.$bus.emit("contractUpdate");
              this.$bus.emit("contractUncheck");
            }
          });
        }
      });
    },
    resetForm() {
      this.renewForm = {
        id: null,
        contractNum: "",
        category: "",
        categoryType: "",
        categoryName: "",
        contractStartTime: "",
        contractAmount: "",
        contractActualAmount: "",
        contactsName: "",
        contactsPhone: "",
        userId: [],
        disposable: false,
        remark: "",
        customerId: "",
        auditStatus: null,
      };
    },
  },
};
</script>

<style scoped lang="less">
.el-form-item {
  width: 46% !important;
  margin-bottom: 22px !important;
}
.el-form {
  display: flex;
  flex-wrap: wrap;
}
</style>
