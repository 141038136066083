<template>
  <div style="position: relative;">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="客户列表" name="first" lazy>
        <customerList ref="customerList"></customerList>
      </el-tab-pane>
      <el-tab-pane label="合同列表" name="second">
        <contractList ref="contractList"></contractList>
      </el-tab-pane>
      <el-tab-pane label="合同审核" name="third" lazy>
        <examineList ref="examineList"></examineList>
      </el-tab-pane>
      <el-tab-pane label="解约合同" name="fifth" lazy>
        <breakContract ref="breakContract"></breakContract>
      </el-tab-pane>
      <el-tab-pane label="解约客户" name="fourth" lazy>
        <termList ref="termList"></termList>
      </el-tab-pane>
      <el-tab-pane label="收款管理" name="sixth" lazy>
        <collectionList ref="collectionList"></collectionList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import customerList from "./components/customerList.vue";
import contractList from "./components/contractList.vue";
import examineList from "./components/examineList.vue";
import termList from "./components/termList.vue";
import collectionList from "./components/collectionList.vue";
import breakContract from "./components/breakContract.vue";
export default {
  name: "coopCustom",
  components: {
    customerList,
    contractList,
    examineList,
    termList,
    collectionList,
    breakContract,
  },
  data() {
    return {
      activeName: "second",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$refs.contractList.getList();
      this.$refs.contractList.getTerm();
    },
    getList() {
      if (this.activeName == "first") {
        this.$refs.customerList?.getList();
      } else if (this.activeName == "second") {
        this.$refs.contractList.getList();
      } else if (this.activeName == "third") {
        this.$refs.examineList?.getList();
      } else if (this.activeName == "fourth") {
        this.$refs.termList?.getList();
      } else if (this.activeName == "sixth") {
        this.$refs.collectionList?.getList();
      }else if (this.activeName == "fifth") {
        this.$refs.breakContract?.getList();
      }
    },
    handleClick(tab) {
      this.activeName = tab.props.name;
      this.getList();
    },
    moveContract(name) {
      this.activeName = "second";
      this.$refs.contractList.init(name);
    },
  },
};
</script>

<style></style>
