<template>
  <div>
    <div class="top_select">
      <div class="select_left">
        <div class="every_select">
          <el-button
            icon="RefreshRight"
            size="small"
            style="margin-right: 5px;"
            @click="getList()"
          ></el-button>
          <el-date-picker
            v-model="listQuery.contractStartTime"
            type="month"
            size="small"
            value-format="YYYY-MM"
            placeholder="请选择月份"
            @change="getList"
            style="width: 100px; margin-right: 5px"
            :clearable="false"
          />
          <el-input
            placeholder="请输入公司名称"
            v-model="listQuery.customerName"
            style="width: 200px; margin-right: 5px"
            size="small"
            @keyup.enter="getList"
            clearable
          />
          <el-button
            type="primary"
            @click="getList()"
            size="small"
            icon="Search"
            >搜索</el-button
          >
        </div>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 5px;width:400px">
            <el-form-item label="联系人：" :label-width="formLabelWidth">
              <el-input v-model="listQuery.contactsName" size="small" placeholder="请输入联系人" style="width: 200px;"></el-input>
            </el-form-item>
            <el-form-item label="合同性质：" :label-width="formLabelWidth">
              <el-select
                v-model="listQuery.disposable"
                placeholder="请选择"
                size="small"
                style="width: 200px;"
              >
                <el-option label="全部" :value="null" />
                <el-option label="周期性" value="0" />
                <el-option label="一次性" value="1" />
              </el-select>
            </el-form-item>
            <el-form-item label="合同签订时间：" :label-width="formLabelWidth">
              <el-date-picker
                v-model="listQuery.contractSignTime"
                type="month"
                placeholder="选择月份"
                size="small"
                value-format="YYYY-MM"
                style="width: 200px;"
              />
            </el-form-item>
            <el-form-item label="销售经理：" :label-width="formLabelWidth">
              <selectuser
                code="coopCustom"
                v-model:userId="listQuery.userId"
                style="width: 200px;"
              ></selectuser>
            </el-form-item>
            <el-form-item label="合同结束期间：" :label-width="formLabelWidth">
              <el-date-picker
                v-model="listQuery.contractStartYear"
                type="date"
                placeholder="选择结束期起"
                size="small"
                value-format="YYYY-MM-DD"
                style="width: 120px"
              />-<el-date-picker
                v-model="listQuery.contractEndYear"
                type="date"
                placeholder="选择结束期止"
                size="small"
                value-format="YYYY-MM-DD"
                style="width: 120px"
              />
            </el-form-item>
          </el-form>
        </search>
        <span style="margin-left: 5px;"
          ><el-switch
            v-model="listQuery.qfAmount"
            active-text="欠费合同"
            :active-value="3"
            :inactive-value="0"
            inactive-text=""
            @change="getList"
            size="small"
          ></el-switch
        ></span>
        <!-- <span style="margin-left: 5px;"
          ><el-switch
            v-model="listQuery.disposable"
            active-text="一次性合同"
            :active-value="'1'"
            :inactive-value="'0'"
            inactive-text=""
            @change="getList"
            size="small"
          ></el-switch
        ></span> -->
      </div>
      <div>
        <el-button
          type="primary"
          plain
          size="small"
          icon="FolderOpened"
          @click="daochu"
          :loading="daochuLoading"
          :disabled="!$buttonStatus('hzkh_htdc')"
          >导出</el-button
        >
      </div>
    </div>
    <el-table
      :data="startsList"
      style="margin-top: 10px"
      border
      :height="contentStyleObj"
      @selection-change="handleSelectContract"
      v-loading="listLoading"
      stripe
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center" fixed />
      <el-table-column
        type="index"
        label="序号"
        width="70"
        align="center"
        fixed
      />
      <el-table-column
        prop="paymentTime"
        label="收款月"
        min-width="120"
        align="left"
        show-overflow-tooltip
      />
      <el-table-column
        prop="customerName"
        label="公司名称"
        min-width="120"
        align="left"
        show-overflow-tooltip
      />
      <el-table-column
        prop="categoryName"
        label="服务项目"
        min-width="120"
        align="left"
        show-overflow-tooltip
      />
      <el-table-column
        prop="contractStartTime"
        label="合同开始时间"
        min-width="120"
        align="left"
        show-overflow-tooltip
      />
      <el-table-column
        prop="contractEndTime"
        label="合同结束时间"
        min-width="120"
        align="left"
        show-overflow-tooltip
      />
      <el-table-column
        prop="contractAmount"
        label="合同金额"
        min-width="120"
        align="left"
        show-overflow-tooltip
      />
      <el-table-column
        prop="totalAmount"
        label="合同已收款金额"
        min-width="120"
        align="left"
        show-overflow-tooltip
      />
      <el-table-column
        prop="currentMonthAmount"
        label="合同当月收款金额"
        min-width="120"
        align="left"
        show-overflow-tooltip
      />
      <el-table-column
        prop="qfNumber"
        label="合同未收款金额"
        min-width="120"
        align="left"
        show-overflow-tooltip
      />
      <el-table-column
        prop="disCodes"
        label="销售经理"
        min-width="120"
        align="left"
        show-overflow-tooltip
      />
    </el-table>
    <div class="select">
      <div></div>
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
        type="coopCustom"
      />
    </div>
  </div>
</template>

<script>
import {
  contractRecordStList,
  exportContractRecordStList,
} from "@/api/crm";
import selectBusiness from "../../components/selectBusiness.vue";
import business from "./business.vue";
import signContract from "./signContract.vue";
export default {
  name: "collectionList",
  components: {
    selectBusiness,
    business,
    signContract,
  },
  data() {
    return {
      formLabelWidth: "110px",
      isAdmin: this.$store.getters["user/user"].isAdmin,
      isLeader: this.$store.getters["user/user"].isLeader,
      listLoading: false,
      sels: [],
      total: "",
      listQuery: {
        limit: 20,
        page: 1,
        auditStatus: 1,
        customerName: null,
        contractStartTime: new Date().toISOString().slice(0, 7),
        contactsName:'',
        disposable:null,
        contractSignTime:'',
        userId:null,
        contractStartYear:'',
        contractEndYear:'',
        qfAmount:0,
        disposable:'0'
      },
      startsList: [],
      contractDialogVisible: false,
      daochuLoading: false,
    };
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem("coopCustom")
      ? localStorage.getItem("coopCustom") * 1
      : 20;
    this.contentStyleObj = this.$getHeight(228);
    this.getList();
  },

  methods: {
    getList() {
      this.listLoading = true;
      contractRecordStList(this.listQuery).then((res) => {
        console.log(res);
        this.listLoading = false;
        if (res.data.msg == "success") {
          this.startsList = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectContract(sels) {
      this.sels = sels;
    },
    cancel(){
      this.listQuery={
        limit: 20,
        page: 1,
        auditStatus: 1,
        customerName: null,
        contractStartTime: new Date().toISOString().slice(0, 7),
        contactsName:'',
        disposable:null,
        contractSignTime:'',
        userId:null,
        contractStartYear:'',
        contractEndYear:'',
        qfAmount:0,
        disposable:'0'
      }
      this.getList()
    },
    //导出
    daochu() {
      this.daochuLoading = true;
      let ids = [];
      this.sels.map((v) => {
        ids.push(v.id);
      });
      let param = {
        ids: ids,
        query: this.listQuery,
      };
      exportContractRecordStList(param).then((res) => {
        this.daochuLoading = false;
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}
.select_left {
  display: flex;
}
.every_select {
  margin-right: 5px;
}
.el-form-item {
  margin-bottom: 20px !important;
}
.count {
  font-size: 14px;
  margin-right: 5px;
}
:deep(.el-table__cell) {
  padding: 3px 0 !important;
}
.el-select {
  margin: 0 5px 0 0 !important;
}
:deep(.el-radio-button__original-radio:checked + .el-radio-button__inner) {
  background-color: var(--themeColor, #17a2b8);
  border-color: var(--themeColor, #17a2b8);
}

.div_p {
  line-height: 23px;
  border-bottom: 1px solid #efefef;
  height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child {
    border-bottom: none;
  }
}
.content_data {
  background-color: #d8ecff;
  margin-bottom: 10px;
  font-size: 12px;
  position: relative;
  .iconfont {
    font-size: 18px;
    //color: var(--themeColor, #17a2b8);
    color: #17a2b8;
  }
}
.each {
  // width: 120px;
  width: 9%;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #333;
}
.each span {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon_bg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 30px;
}
.data_time {
  position: absolute;
  right: 10px;
  top: 10px;
}
.each_right {
  width: calc(100% - 35px);
  margin-left: 5px;
}
.select {
  display: flex;
  justify-content: space-between;
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: #fff !important;
  width: 10px;
}
.el-table th.el-table__cell > .cell.highlight {
  color: #fff !important;
}
</style>
<style>
.loudou .el-icon-arrow-down:before {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62e";
  margin-left: 250px;
}
</style>
