<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="新增账套"
    v-model="dialogFormVisible"
    width="1150px"
  >
    <el-scrollbar :maxHeight="600">
      <el-form
        ref="dataForm"
        :rules="rules"
        :model="temp"
        label-width="170px"
        :inline="true"
        size="small"
      >
        <div class="record">企业基本信息</div>
        <el-form-item label="企业名称:" prop="name" @click.prevent="() => {}">
          <el-input class="w-200" v-model="temp.name" disabled></el-input>
        </el-form-item>
        <el-form-item
          label="所属税务总局:"
          prop="districtCode "
          @click.prevent="() => {}"
        >
          <selectcity
            class="w-200 ml-mr-0"
            v-model:citys="temp.districtCode"
          ></selectcity>
        </el-form-item>
        <el-form-item
          label="纳税人识别编号:"
          prop="taxNo"
          @click.prevent="() => {}"
        >
          <input-common
            v-model:modelValue="temp.taxNo"
            class="w-200"
          ></input-common>
        </el-form-item>
        <el-form-item label="纳税人资格:" prop="type" @click.prevent="() => {}">
          <selecttaxtype
            class="w-200 ml-mr-0"
            v-model:type="temp.type"
          ></selecttaxtype>
        </el-form-item>
        <el-form-item label="公司注册时间:" @click.prevent="() => {}">
          <el-date-picker
            :picker-options="pickerOptions"
            v-model="temp.comSetUpYear"
            type="date"
            clearable
            placeholder="公司注册时间"
            style="width: 200px"
            value-format="YYYY-MM-DD"
          ></el-date-picker>
        </el-form-item>
        <div class="record">
          账套信息<span class="hint">
            <i class="iconfont icon-sanjiaotanhao"></i
            >温馨提示：请正确选择会计制度，账套启用后不可修改！</span
          >
        </div>
        <el-form-item
          label="企业会计制度:"
          prop="accountSystem"
          @click.prevent="() => {}"
        >
          <select-common
            placeholder="请选择会计准则"
            v-model:modelValue="temp.accountSystem"
            :options="kjOptions"
            class="w-200 ml-mr-0"
          />
        </el-form-item>
        <el-form-item
          label="公司类型:"
          prop="companyType"
          @click.prevent="() => {}"
        >
          <select-common
            placeholder="请选择公司类型"
            v-model:modelValue="temp.companyType"
            :options="typeOptions"
            class="w-200 ml-mr-0"
          />
        </el-form-item>
        <el-form-item
          label="账套启用时间:"
          prop="startAccountPeriod"
          @click.prevent="() => {}"
        >
          <el-date-picker
            :picker-options="pickerOptions"
            v-model="temp.startAccountPeriod"
            type="month"
            placeholder="启用月"
            style="width: 200px"
          ></el-date-picker>
          <span class="ml-5 hint"
            ><i class="iconfont icon-sanjiaotanhao"></i
            >注意：账套启用时间创建之后，无法修改，只能重置账套！</span
          >
        </el-form-item>
        <el-form-item label="账套截止时间:" @click.prevent="() => {}">
          <el-date-picker
            :picker-options="pickerOptions"
            v-model="temp.duePeriod"
            clearable
            type="month"
            placeholder="截止月"
            style="width: 200px"
            value-format="YYYYMM"
          ></el-date-picker>
          <el-tooltip
            content="截止账期为最后一个结账账期，非必选，可设置为空"
            placement="top"
          >
            <span class="ml-5 hint">
              <i class="iconfont icon-wenhao"></i>
            </span>
          </el-tooltip>
        </el-form-item>
        <div class="record">电子税局登录信息</div>
        <el-form-item
          label="登录方式:"
          @click.prevent="() => {}"
          prop="loginMethod"
        >
          <select-common
            placeholder="请选择登录方式"
            v-model:modelValue="temp.loginMethod"
            :options="loginOptions"
            class="w-200 ml-mr-0"
          />
        </el-form-item>
        <el-form-item
          label="用户名/手机号:"
          @click.prevent="() => {}"
          prop="zzridno"
          v-if="temp.loginMethod != '政务网登录'"
        >
          <el-autocomplete
            v-model="temp.zzridno"
            class="w-200"
            :fetch-suggestions="searchZzridNo"
            @select="handleSelectZzridno"
          >
            <template #default="{ item }">
              <div>{{ item.loginMethod }}（{{ item.zzridno }}）</div>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item
          :label="temp.loginMethod == '政务网登录' ? '账号:' : '代理机构号:'"
          prop="idNo"
          v-if="temp.loginMethod != '新版登录'"
        >
          <input-common
            v-model:modelValue="temp.idNo"
            class="w-200"
            :placeholder="
              temp.loginMethod == '政务网登录'
                ? '请输入账号'
                : '请输入代理机构号(纳税人识别号)'
            "
          ></input-common>
        </el-form-item>
        <el-form-item label="密码:" prop="zzrmn">
          <el-input v-model="temp.zzrmn" class="w-200"></el-input>
        </el-form-item>
        <el-form-item
          label="短信接收方式:"
          prop="yzmType"
          v-if="temp.loginMethod != '政务网登录'"
        >
          <select-common
            placeholder="请选择短信接收方式"
            v-model:modelValue="temp.yzmType"
            :options="yzmOptions"
            class="w-200 ml-mr-0"
          />
        </el-form-item>
        <div class="record">个税登录信息</div>
        <el-form-item
          label="个税登录方式:"
          prop="personalLoginType"
          @click.prevent="() => {}"
        >
          <select-common
            placeholder="请选择个税登录方式"
            v-model:modelValue="temp.personalLoginType"
            :options="personal_type"
            class="w-200 ml-mr-0"
          />
        </el-form-item>
        <el-form-item
          label="个税申报密码:"
          prop="personalTaxPwd"
          @click.prevent="() => {}"
        >
          <input-common
            v-model:modelValue="temp.personalTaxPwd"
            class="w-200"
            placeholder="请输入个税申报密码"
          ></input-common>
          <span class="help" @click="checkImage"
            >帮助<i class="iconfont icon-wenhao"></i
          ></span>
        </el-form-item>
        <el-form-item
          v-if="temp.personalLoginType == '实名登录'"
          label="个税实名账号:"
          prop="personalIdno"
          @click.prevent="() => {}"
        >
          <input-common
            v-model:modelValue="temp.personalIdno"
            class="w-200"
          ></input-common>
        </el-form-item>
        <div v-if="temp.districtCode == '360000'">
          <div class="record">社保登录信息</div>
          <el-form-item label="社保登录方式:" @click.prevent="() => {}">
            <select-common
              placeholder="请选择社保登录方式"
              v-model:modelValue="temp.socialLoginMethod"
              :options="sb_type"
              class="w-200 ml-mr-0"
            />
          </el-form-item>
          <el-form-item label="社保密码:" @click.prevent="() => {}">
            <input-common
              v-model:modelValue="temp.socialPassword"
              class="w-200"
              placeholder="请输入社保密码"
            ></input-common>
          </el-form-item>
        </div>
        <div class="record">分配人员</div>
        <el-form-item label="人员:" prop="userId" @click.prevent="() => {}">
          <selectuser
            code="bookkeeping"
            v-model:userId="temp.userId"
            class="w-200 ml-mr-0"
          ></selectuser>
        </el-form-item>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="createData()"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- 图片预览 -->
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="imgSrc"
  />
</template>

<script setup>
import { ref } from "vue";
import { companyLoginUserList } from "@/api/company";
import { pushSaveEaCompany } from "@/api/crm";
import { parseTime } from "@/utils";
import selectcity from "@/components/Screening/selectcity";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import { ACCOUNT_SYSTEM_OPTION, COMPANY_TYPE_OPTION } from "@/utils/commonData";
import { ElMessage } from "element-plus";
const emit = defineEmits();
const contractId = ref(0);
const showImagePreview = ref(false);
const imgSrc = ref([]);
const loading = ref(false);
const temp = ref({
  name: "",
  districtCode: [],
  taxNo: "",
  type: "",
  accountSystem: "",
  loginMethod: "代理登录",
  personalLoginType: "申报密码登录",
  personalIdno: "",
  personalTaxPwd: "",
  startAccountPeriod: "",
  password: "",
  idNo: "",
  idType: "居民身份证",
  zzridno: "",
  zzrmn: "",
  zzrxm: "",
  companyType: "有限责任公司",
  userId: [],
});
const dialogFormVisible = ref(false);
const dataForm = ref();
const kjOptions = ACCOUNT_SYSTEM_OPTION;
const typeOptions = COMPANY_TYPE_OPTION;
const loginOptions = [
  { label: "新版登录", value: "新版登录" },
  { label: "代理登录", value: "代理登录" },
  { label: "政务网登录", value: "政务网登录" },
];
const yzmOptions = [
  { label: "自动登录", value: 0 },
  { label: "手动登录", value: 1 },
];
const personal_type = [
  {
    value: "实名登录",
    label: "实名登录",
  },
  {
    value: "申报密码登录",
    label: "申报密码登录",
  },
];
const sb_type = [
  {
    value: "账号密码登录",
    label: "账号密码登录",
  },
];
const rules = ref({
  name: [{ required: true, message: "公司名不能为空", trigger: "change" }],
  districtCode: [
    { required: true, message: "所属税务总局不能为空", trigger: "change" },
  ],
  startAccountPeriod: [
    { required: true, message: "请选择账号启用时间", trigger: "change" },
  ],
  taxNo: [
    { required: true, message: "请输入纳税人识别编号", trigger: "change" },
    { min: 15, message: "税号至少15位", trigger: "blur" },
  ],
  type: [{ required: true, message: "请选择纳税人资格", trigger: "change" }],
  accountSystem: [
    { required: true, message: "请选择企业会计制度", trigger: "change" },
  ],
  companyType: [
    { required: true, message: "请选择企业类型", trigger: "change" },
  ],
  loginMethod: [
    { required: true, message: "请选择国税登录方式", trigger: "change" },
  ],
  personalLoginType: [
    { required: true, message: "请选择个税登录方式", trigger: "change" },
  ],
  personalIdno: [
    { required: true, message: "请输入个税实名账号", trigger: "change" },
  ],
  personalTaxPwd: [
    { required: true, message: "请输入个税申报密码", trigger: "change" },
  ],
  zzrmn: [{ required: true, message: "请输入密码", trigger: "change" }],
  zzridno: [
    { required: true, message: "请输入办税员的身份证号码", trigger: "change" },
  ],
  zzrxm: [{ required: true, message: "请输入办税员姓名", trigger: "change" }],
  idNo: [
    { required: true, message: "请输入正确的证件号码", trigger: "change" },
  ],
  userId: [{ required: true, message: "请分配人员", trigger: "change" }],
});
const init = (row) => {
  resetTemp();
  contractId.value = row.id;
  temp.value.name = row.customerName;
  dialogFormVisible.value = true;
};

const searchZzridNo = (queryString, cb) => {
  if (queryString == "" || queryString.length < 2) {
    return;
  }
  companyLoginUserList({
    zzridno: queryString,
    loginMethod: temp.value.loginMethod,
  }).then((res) => {
    let results = res.data?.data?.list || [];
    if (results) {
      cb && cb(results);
    }
  });
};
const handleSelectZzridno = (item) => {
  temp.value.zzridno = item.zzridno;
  temp.value.yzmType = item.yzmType;
  temp.value.zzrmn = item.zzrmn;
  temp.value.idNo = item.idNo;
};
const createData = () => {
  dataForm.value.validate((valid) => {
    if (valid) {
      loading.value = true;
      let info = Object.assign({}, temp.value);
      info.startAccountPeriod = parseTime(
        temp.value.startAccountPeriod,
        "{y}{m}"
      );
      let param = {
        companyList: info,
        contractId: contractId.value,
        userId: temp.value.userId,
      };
      pushSaveEaCompany(param).then((res) => {
        loading.value = false;
        if (res.data.msg == "success") {
          ElMessage.success("操作成功");
          dialogFormVisible.value = false;
          emit("success");
        }
      });
    }
  });
};
const checkImage = () => {
  if (temp.value.personalLoginType == "实名登录") {
    imgSrc.value = [
      "https://file.listensoft.net/mnt/v2/operatingSteps/zzrkjdsmlogin.png",
    ];
  } else {
    imgSrc.value = [
      "https://file.listensoft.net/mnt/v2/operatingSteps/zzrkjdsbmmlogin.png",
    ];
  }
  showImagePreview.value = true;
};
const resetTemp = () => {
  temp.value = {
    name: "",
    districtCode: [],
    taxNo: "",
    type: "",
    accountSystem: "",
    loginMethod: "代理登录",
    personalLoginType: "申报密码登录",
    personalIdno: "",
    personalTaxPwd: "",
    startAccountPeriod: "",
    password: "",
    idNo: "",
    idType: "居民身份证",
    zzridno: "",
    zzrmn: "",
    zzrxm: "",
    companyType: "有限责任公司",
    userId: [],
  };
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.record {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  padding-bottom: 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid #c2c2c2;
}
.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.hint {
  color: red;
  margin-left: 30px;
  font-weight: normal !important;
  .icon-sanjiaotanhao {
    color: #e6a23c;
    margin-right: 4px;
  }
  .icon-wenhao {
    font-size: 13px;
    cursor: pointer;
  }
}
.help {
  color: #17a2b8;
  font-size: 13px;
  position: absolute;
  right: -44px;
  cursor: pointer;
  i {
    color: #17a2b8;
    font-size: 13px;
    margin-left: 3px;
  }
}
</style>
