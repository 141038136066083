<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    :title="info.customerName"
    width="70%"
  >
    <div style="font-size: 13px;margin-bottom: 10px;">
      合同金额：{{ contractCount }}&nbsp;&nbsp;未收款金额：{{ wskCount }}
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      border
      :height="contentStyleObj"
      stripe
      v-loading="loading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center" fixed />
      <el-table-column
        type="index"
        label="序号"
        width="55"
        align="center"
        fixed
      />
      <el-table-column
        prop="paymentTime"
        label="收款时间"
        align="center"
        min-width="120"
      />
      <el-table-column prop="paymentName" label="收款人" align="center" />
      <el-table-column prop="paymentMethod" label="收款方式" align="center" />
      <el-table-column prop="categoryName" label="服务项目" align="center" />
      <el-table-column
        prop="collectionStartTime"
        label="收款时间起"
        align="center"
        min-width="120"
      />
      <el-table-column
        prop="collectionEndTime"
        label="收款时间止"
        align="center"
        min-width="120"
      />
      <el-table-column prop="paymentAmount" label="实收金额" align="center" />
      <el-table-column prop="remark" label="备注" align="center" />
      <el-table-column label="操作" align="center" min-width="150">
        <template #default="scope">
          <el-button type="primary" plain size="small" @click="check(scope.row)"
            >收据</el-button
          >
          <el-button
            type="danger"
            plain
            size="small"
            @click="delRecord(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="收据"
    width="900px"
  >
    <div class="main">
      <div class="title">
        <span>收&nbsp;&nbsp;据</span>
      </div>
      <div class="top" style="margin-top: 40px;">
        <div>收款日期：{{ recordInfo.paymentTime }}</div>
      </div>
      <div>
        <el-row :span="24">
          <el-col :xs="4" :sm="4" :lg="4"> <div>交款单位</div></el-col>
          <el-col :xs="8" :sm="8" :lg="8">
            <div class="text">{{ info.customerName }}</div></el-col
          >
          <el-col :xs="4" :sm="4" :lg="4"> <div>收款方式</div> </el-col>
          <el-col :xs="8" :sm="8" :lg="8">
            <div>{{ recordInfo.paymentMethod }}</div></el-col
          >
        </el-row>
        <el-row :span="24">
          <el-col :xs="4" :sm="4" :lg="4"> <div>人民币（大写）</div></el-col>
          <el-col :xs="8" :sm="8" :lg="8">
            <div>{{ $saveBig(recordInfo.paymentAmount) }}</div></el-col
          >
          <el-col :xs="4" :sm="4" :lg="4"> <div>人民币（小写）</div></el-col>
          <el-col :xs="8" :sm="8" :lg="8">
            <div>{{ $comdify(recordInfo.paymentAmount) }}</div></el-col
          >
        </el-row>
        <el-row :span="24">
          <el-col :xs="4" :sm="4" :lg="4"> <div>收款是由</div></el-col>
          <el-col :xs="20" :sm="20" :lg="20">
            <div>{{ recordInfo.categoryName }}</div></el-col
          >
        </el-row>
      </div>
      <div class="top">
        <div>收款单位：{{ orgName }}</div>
        <div>收款人：{{ recordInfo.paymentName }}</div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="dayin"
          v-loading="btnLoading"
          >打印</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { collectionRecordList, deleteCollectionRecord } from "@/api/crm";
import { wbVoucherFxOther } from "@/api/export";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      list: [],
      contentStyleObj: {},
      info: {},
      dialogVisible: false,
      recordInfo: {},
      orgName: this.$store.getters["user/user"].orgName,
      loading: false,
      btnLoading: false,
      contractCount: 0,
      wskCount: 0,
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(280);
  },
  methods: {
    init(e) {
      this.info = e;
      this.dialogFormVisible = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      collectionRecordList({
        contractId: this.info.id,
      }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data.list;
          this.contractCount = res.data.data.contractCount;
          this.wskCount = res.data.data.wskCount;
        }
      });
    },
    check(row) {
      this.recordInfo = row;
      this.dialogVisible = true;
    },
    delRecord(row) {
      this.$confirm("确认删除此条收款记录吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteCollectionRecord({ id: row.id }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    dayin() {
      this.btnLoading = true;
      let param = {
        outType: "pdf",
        exportType: "receipt",
        query: {
          recordId: this.recordInfo.id,
        },
      };
      wbVoucherFxOther(param).then((res) => {
        this.btnLoading = false;
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  box-shadow: 0 2px 10px #ddd;
  background-color: #fbfcfc;
  padding: 10px;
}
.el-col-sm-8 {
  border-right: 1px solid #b9b9b9;
  font-size: 14px;
  height: 34px;
  line-height: 34px;

  div {
    padding-left: 10px;
    box-sizing: border-box;
  }
}
.el-col-sm-4 {
  border-right: 1px solid #b9b9b9;
  padding-right: 10px;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
  div {
    padding-left: 10px;
  }
}
.el-col-sm-20 {
  border-right: 1px solid #b9b9b9;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
  div {
    padding-left: 10px;
    box-sizing: border-box;
  }
}
.el-row {
  display: flex;
  border-left: 1px solid #b9b9b9;
  border-bottom: 1px solid #b9b9b9;
}
.el-row:first-child {
  border-top: 1px solid #b9b9b9;
}
.el-row:last-child {
  border-bottom: 1px solid #b9b9b9;
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.title {
  margin: 0 auto;
  text-align: center;
}
.title span {
  display: inline-block;
  font-size: 20px;
  color: #333;
  text-align: center;
  border-bottom: 4px double #333;
  padding: 2px 15px;
}
.text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
