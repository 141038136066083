<template>
  <div>
    <div class="top_select">
      <div>
        <el-button
          icon="RefreshRight"
          size="small"
          style="margin-right: 5px;"
          @click="getList()"
        ></el-button>
        <el-input
          placeholder="请输入公司名称"
          v-model="listQuery.customerName"
          style="width: 200px; margin-right: 5px"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-button type="primary" @click="getList()" size="small" icon="Search"
          >搜索</el-button
        >
      </div>
      <div>
        <el-button
          type="primary"
          size="small"
          icon="Folder"
          @click="daochu"
          :loading="daochuLoading"
          :disabled="!$buttonStatus('jykh_dc')"
          >导出</el-button
        >
      </div>
    </div>
    <el-table
      :data="termCustomerList"
      border
      @selection-change="handleSelectCustomer"
      style="width: 100%; margin-top: 10px"
      :height="contentStyleObj"
      v-loading="listLoading"
      stripe
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center" fixed />
      <el-table-column
        type="index"
        label="序号"
        width="70"
        align="center"
        fixed
      />
      <el-table-column
        prop="customerName"
        label="公司名称"
        align="left"
        fixed
        min-width="250"
      >
        <template #default="scope">
          <div style="display: flex;align-items: center;">
            <div
              class="item_icon"
              style="width: 95%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
            >
              <span>{{ scope.row.customerName }}</span>
            </div>
            <el-tooltip content="复制" placement="top" effect="dark">
              <i
                class="iconfont icon-fuzhi1"
                style="color:var(--themeColor,#17a2b8);cursor: pointer;"
                @click="$copyComName(scope.row.customerName)"
              >
              </i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="linkman"
        label="联系人"
        width="150"
        align="center"
      />
      <el-table-column
        prop="linkmanTel"
        label="联系人电话"
        width="105"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="disCodes"
        label="销售经理"
        width="150"
        align="center"
      />
      <el-table-column
        prop="terminationReason"
        label="解约原因"
        width="180"
        align="center"
        show-overflow-tooltip="false"
      />
      <el-table-column
        prop="terminationDate"
        label="解约时间"
        width="180"
        align="center"
      />
      <el-table-column label="操作" align="center" width="350">
        <template #default="scope">
          <el-button
            :disabled="!$buttonStatus('hzkh_jykhsc')"
            type="danger"
            size="small"
            @click="delDown(scope.row)"
            icon="Delete"
            plain
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="top_select" style="margin-top: 10px;height: 28px;">
      <div>
        <!-- <el-button
          v-if="$buttonStatus('jykh_hy')"
          type="primary"
          size="small"
          :disabled="this.sels.length === 0"
          @click="SaveAll()"
          icon="Refresh"
          >批量还原</el-button
        > -->
        <!-- <el-button
          v-if="$buttonStatus('jykh_sc')"
          type="danger"
          size="small"
          :disabled="this.sels.length === 0"
          @click="delAll()"
          icon="Delete"
          >批量删除</el-button
        > -->
      </div>
      <div class="pagination" style="margin-top: 0">
        <qzf-pagination
          v-show="total > 0"
          :total="total"
          v-model:page="listQuery.page"
          v-model:limit="listQuery.limit"
          @pagination="getList()"
          type="coopCustom"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCustomerList,
  updateCustomer,
  delCustomerNew,
  saveStatus,
  batchDelCustomer,
  exportCustomerNew
} from "@/api/crm";
export default {
  name: "termCustom",
  data() {
    return {
      total: "",
      sels: [],
      listQuery: {
        page: 1,
        limit: 20,
        customerType: "3",
        customerName: "",
      },
      termCustomerList: [],
      listLoading: false,
      daochuLoading:false
    };
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem('coopCustom') ? localStorage.getItem('coopCustom')*1 : 20
    this.contentStyleObj = this.$getHeight(238);
    this.getList();
    // this.init();
  },
  methods: {
    init() {
      this.$bus.off("termUpdate");
      this.$bus.on("termUpdate", (val) => {
        this.getList();
      });
    },
    getList() {
      this.listLoading = true;
      getCustomerList(this.listQuery).then((res) => {
        this.listLoading = false;
        if (res.data.msg == "success") {
          this.termCustomerList = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    reCoop(row) {
      this.$confirm("确定还原到合作客户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.customerType = "2";
          updateCustomer(row).then((res) => {
            if (res.data.msg == "success") {
              this.$bus.emit("coopUpdate");
              this.$bus.emit("contractUpdate");
              this.getList();
              this.$qzfMessage("修改成功");
            }
          });
        })
        .catch(() => {});
    },
    //彻底删除
    delDown(row) {
      this.$confirm("确定删除客户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delCustomerNew({ id: row.id }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    //批量删除或还原
    handleSelectCustomer(sels) {
      this.sels = sels;
    },
    delAll() {
      this.$confirm("确定删除客户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = [];
          this.sels.map((item) => {
            ids.push({
              id: item.id,
            });
          });
          batchDelCustomer(ids).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    SaveAll() {
      this.$confirm("确定还原到合作客户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = [];
          this.sels.map((item) => {
            params.push({
              id: item.id,
              customerType: "2",
            });
          });
          saveStatus(params).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("修改成功");
              this.$bus.emit("coopUpdate");
              this.$bus.emit("contractUpdate");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
     //导出
     daochu() {
      this.daochuLoading = true;
      let ids = []
      this.sels.map(v=>{
        ids.push(v.id)
      })
      let param = {
        query:this.listQuery,
        ids: ids,
      }
      exportCustomerNew(param).then((res) => {
        this.daochuLoading = false;
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  display: flex;
  justify-content: space-between;
}
</style>
